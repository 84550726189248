import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_COLLECTIONS } from "redux/model/collections/collections/constants";
import { apiListCollections } from "api/collection/apiCollection";
import { listCollectionsFailed, listCollectionsSuccess } from "redux/model/collections/collections/collectionsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* collectionsSaga() {
  yield all([
    fork(watchListCollections),
  ]);
}

// List Collections
export function* watchListCollections() {
  yield takeEvery(LIST_COLLECTIONS, sagaListCollections);
}

export function* sagaListCollections() {
  try {
    const response = yield call(apiListCollections);

    const { collections } = response.data;

    yield put(listCollectionsSuccess({ collections }));

  } catch (e) {
    yield put(listCollectionsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default collectionsSaga;
