import {
  CREATE_ATTACHMENT_LIST_ATTACHMENT,
  CREATE_ATTACHMENT_LIST_ATTACHMENT_SUCCESS,
  INIT_ATTACHMENT_LISTS,
  LIST_ATTACHMENT_LISTS,
  LIST_ATTACHMENT_LISTS_FAILED,
  LIST_ATTACHMENT_LISTS_SUCCESS
} from "redux/model/attachmentLists/attachmentLists/constants";

export const initAttachmentLists = () => ({
  type: INIT_ATTACHMENT_LISTS,
  payload: {}
});

export const listAttachmentLists = () => ({
  type: LIST_ATTACHMENT_LISTS,
  payload: {}
});

export const listAttachmentListsSuccess = ({ attachmentLists }) => ({
  type: LIST_ATTACHMENT_LISTS_SUCCESS,
  payload: { attachmentLists }
});

export const listAttachmentListsFailed = ({ error }) => ({
  type: LIST_ATTACHMENT_LISTS_FAILED,
  payload: { error }
});

export const createAttachmentListAttachment = ({ attachmentList, lineAttachment }) => ({
  type: CREATE_ATTACHMENT_LIST_ATTACHMENT,
  payload: { attachmentList, lineAttachment }
});

export const createAttachmentListAttachmentSuccess = ({ attachmentList, lineAttachment }) => ({
  type: CREATE_ATTACHMENT_LIST_ATTACHMENT_SUCCESS,
  payload: { attachmentList, lineAttachment }
});