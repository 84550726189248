import React from "react";
import { NEW_PACK_BATCH_UPLOAD_PATH } from "routes/packBatchUpload/constants";
import { PrivateRoute } from "@tmatt-tech/react_common";

const NewPackBatchUpload = React.lazy(() => import('pages/packBatchUploads/new/NewPackBatchUpload'));

export const packBatchUploadRoutes = {
  path: NEW_PACK_BATCH_UPLOAD_PATH,
  name: '包裹信息上传',
  icon: 'far fa-cloud-upload',
  header: "物流",
  component: NewPackBatchUpload,
  route: PrivateRoute,
};

export const hiddenPackBatchUploadRoutes = {
  children: [

  ]
}
