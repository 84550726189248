export const INIT_PRODUCT_PACKAGE = 'INIT_PRODUCT_PACKAGE';

export const FETCH_PRODUCT_PACKAGE = 'FETCH_PRODUCT_PACKAGE';
export const FETCH_PRODUCT_PACKAGE_SUCCESS = 'FETCH_PRODUCT_PACKAGE_SUCCESS';
export const FETCH_PRODUCT_PACKAGE_FAILED = 'FETCH_PRODUCT_PACKAGE_FAILED';

export const CREATE_PRODUCT_PACKAGE = 'CREATE_PRODUCT_PACKAGE';
export const CREATE_PRODUCT_PACKAGE_SUCCESS = 'CREATE_PRODUCT_PACKAGE_SUCCESS';
export const CREATE_PRODUCT_PACKAGE_FAILED = 'CREATE_PRODUCT_PACKAGE_FAILED';

export const UPDATE_PRODUCT_PACKAGE = 'UPDATE_PRODUCT_PACKAGE';
export const UPDATE_PRODUCT_PACKAGE_SUCCESS = 'UPDATE_PRODUCT_PACKAGE_SUCCESS';
export const UPDATE_PRODUCT_PACKAGE_FAILED = 'UPDATE_PRODUCT_PACKAGE_FAILED';

export const DELETE_PRODUCT_PACKAGE = 'DELETE_PRODUCT_PACKAGE';
export const DELETE_PRODUCT_PACKAGE_SUCCESS = 'DELETE_PRODUCT_PACKAGE_SUCCESS';
export const DELETE_PRODUCT_PACKAGE_FAILED = 'DELETE_PRODUCT_PACKAGE_FAILED';

export const NEW_PRODUCT_PACKAGE = 'NEW_PRODUCT_PACKAGE';
export const PULL_PRODUCT_PACKAGE = 'PULL_PRODUCT_PACKAGE';

export const ASSIGN_PRODUCT_PACKAGE_OPTION_ITEM_TITLES = 'ASSIGN_PRODUCT_PACKAGE_OPTION_ITEM_TITLES';
export const ASSIGN_PRODUCT_PACKAGE_PRICE = 'ASSIGN_PRODUCT_PACKAGE_PRICE';
export const ASSIGN_PRODUCT_PACKAGE_QUANTITY = 'ASSIGN_PRODUCT_PACKAGE_QUANTITY';
export const ASSIGN_PRODUCT_PACKAGE_SKU = 'ASSIGN_PRODUCT_PACKAGE_SKU';
export const ASSIGN_PRODUCT_PACKAGE_DESTROY = 'ASSIGN_PRODUCT_PACKAGE_DESTROY';
