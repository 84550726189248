import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_PATH, INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_PATH, NEW_EXPORT_INTERNATIONAL_BATCH_UPLOAD_PATH, SHOW_EXPORT_INTERNATIONAL_BATCH_UPLOAD_PATH } from "routes/exportInternationalBatchUpload/constants";

const ExportInternationalBatchUploads = React.lazy(() => import('pages/exportInternationalBatchUploads/index/ExportInternationalBatchUploads'));
const NewExportInternationalBatchUpload = React.lazy(() => import('pages/exportInternationalBatchUploads/new/NewExportInternationalBatchUpload'));
const ShowExportInternationalBatchUpload = React.lazy(() => import('pages/exportInternationalBatchUploads/show/ShowExportInternationalBatchUpload'));

export const exportInternationalBatchUploadRoutes = {
  path: BASE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_PATH,
  name: '出库国际运单表上传',
  icon: 'far fa-star',
  component: NewExportInternationalBatchUpload,
  route: PrivateRoute,
  // children: [
  //   {
  //     path: INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_PATH,
  //     exact: true,
  //     name: 'ExportInternationalBatchUploads',
  //     component: ExportInternationalBatchUploads,
  //     route: PrivateRoute,
  //   },
  //   {
  //     path: NEW_EXPORT_INTERNATIONAL_BATCH_UPLOAD_PATH,
  //     exact: true,
  //     name: 'Add New',
  //     component: NewExportInternationalBatchUpload,
  //     route: PrivateRoute,
  //   },
  // ],
};

export const hiddenExportInternationalBatchUploadRoutes = {
  children: [
    // {
    //   path: SHOW_EXPORT_INTERNATIONAL_BATCH_UPLOAD_PATH,
    //   exact: true,
    //   name: 'Show ExportInternationalBatchUpload',
    //   component: ShowExportInternationalBatchUpload,
    //   route: PrivateRoute,
    // }
  ]
}
