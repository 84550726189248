import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  settleDownProductPackageMappers
} from "redux/model/productPackageMappers/productPackageMappers/productPackageMappersActions";
import { throwFrontError } from "@tmatt-tech/react_common";
import _ from 'lodash';
import {
  ASSIGN_OPTION_ITEM_TITLE_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
  PULL_OPTION_ITEM_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS
} from "redux/model/optionItems/optionItems/constants";
import { assignOptionItemTitle, pullOptionItem } from "redux/model/optionItems/optionItems/optionItemsActions";

function* optionItemsSaga() {
  yield all([
    fork(watchPullOptionItemSettleDownProductPackageMappers),
    fork(watchAssignOptionItemTitleSettleDownProductPackageMappers),
  ]);
}

// Pull Option Item Settle Down Product Package Mappers
export function* watchPullOptionItemSettleDownProductPackageMappers() {
  yield takeEvery(
    PULL_OPTION_ITEM_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
    sagaPullOptionItemSettleDownProductPackageMappers
  );
}

export function* sagaPullOptionItemSettleDownProductPackageMappers({ payload }) {
  try {
    const {
      optionItem,
      resource,
      options,
      optionItems,
      productPackageMappers,
      productPackages,
    } = payload;

    yield put(pullOptionItem({ optionItem, resource }));
    yield put(settleDownProductPackageMappers({
      options: _.map(options, (option) => {
        if (_.includes(option.optionItemIds, optionItem.id)) {
          return {
            ...option,
            optionItemIds: _.without(option.optionItemIds, optionItem.id),
          };
        }

        return option;
      }),
      optionItems: _.filter(optionItems, (item) => {
        return item.id !== optionItem.id
      }),
      productPackageMappers,
      productPackages,
    }));

  } catch (e) {
    throwFrontError(e);
  }
}

// Assign Option Item Title Settle Down Product Package Mappers
export function* watchAssignOptionItemTitleSettleDownProductPackageMappers() {
  yield takeEvery(
    ASSIGN_OPTION_ITEM_TITLE_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
    sagaAssignOptionItemTitleSettleDownProductPackageMappers
  );
}

export function* sagaAssignOptionItemTitleSettleDownProductPackageMappers({ payload }) {
  try {
    const {
      optionItem,
      title,
      options,
      optionItems,
      productPackageMappers,
      productPackages,
    } = payload;

    yield put(assignOptionItemTitle({ optionItem, title }));
    yield put(settleDownProductPackageMappers({
      optionItem,
      title,
      options,
      optionItems,
      productPackageMappers,
      productPackages,
    }));

  } catch (e) {
    throwFrontError(e);
  }
}


export default optionItemsSaga;
