import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PRODUCTS } from "redux/model/products/products/constants";
import { apiListProductNormals } from "api/product/apiProduct";
import { listProductsFailed, listProductsSuccess } from "redux/model/products/products/productsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* productsSaga() {
  yield all([
    fork(watchListProducts),
  ]);
}

// List Products
export function* watchListProducts() {
  yield takeEvery(LIST_PRODUCTS, sagaListProducts);
}

export function* sagaListProducts() {
  try {
    const response = yield call(apiListProductNormals);

    const { products } = response.data;

    yield put(listProductsSuccess({ products }));

  } catch (e) {
    yield put(listProductsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default productsSaga;
