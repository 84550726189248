import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_PRODUCT_PACKAGE_MAPPER_API,
  INDEX_PRODUCT_PACKAGE_MAPPERS_API,
  FETCH_PRODUCT_PACKAGE_MAPPER_API,
  DELETE_PRODUCT_PACKAGE_MAPPER_API,
  UPDATE_PRODUCT_PACKAGE_MAPPER_API
} from "api/productPackageMapper/constants";

export const apiListProductPackageMappers = () => {
  const { url, method } = INDEX_PRODUCT_PACKAGE_MAPPERS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateProductPackageMapper = ({ productPackageMapper }) => {
  const { url, method } = CREATE_PRODUCT_PACKAGE_MAPPER_API;

  return api({
    method,
    url,
    data: productPackageMapperParams({ productPackageMapper }),
  });
};

export const apiFetchProductPackageMapper = ({ productPackageMapperId }) => {
  const { url, method } = FETCH_PRODUCT_PACKAGE_MAPPER_API;

  return api({
    method,
    url: generateRoute(url, productPackageMapperId),
  });
};

export const apiUpdateProductPackageMapper = ({ productPackageMapper }) => {
  const { url, method } = UPDATE_PRODUCT_PACKAGE_MAPPER_API;

  return api({
    method,
    url: generateRoute(url, productPackageMapper.id),
    data: productPackageMapperParams({ productPackageMapper }),
  });
};

export const apiDeleteProductPackageMapper = ({ productPackageMapper }) => {
  const { url, method } = DELETE_PRODUCT_PACKAGE_MAPPER_API;

  return api({
    method,
    url: generateRoute(url, productPackageMapper.id),
  });
};

const productPackageMapperParams = ({ productPackageMapper }) => {
  const {} = productPackageMapper;

  return {
  }
};
