import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_ORDER_DECLARATION,
  DELETE_ORDER_DECLARATION,
  FETCH_ORDER_DECLARATION,
  UPDATE_ORDER_DECLARATION
} from "redux/model/orderDeclarations/orderDeclaration/constants";
import {
  apiCreateOrderDeclaration,
  apiDeleteOrderDeclaration,
  apiFetchOrderDeclaration,
  apiUpdateOrderDeclaration
} from "api/orderDeclaration/apiOrderDeclaration";
import {
  createOrderDeclarationFailed,
  createOrderDeclarationSuccess,
  deleteOrderDeclarationFailed,
  deleteOrderDeclarationSuccess,
  fetchOrderDeclarationFailed,
  fetchOrderDeclarationSuccess,
  initOrderDeclaration,
  updateOrderDeclarationFailed,
  updateOrderDeclarationSuccess
} from "redux/model/orderDeclarations/orderDeclaration/orderDeclarationActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_ORDER_SEA_FREIGHTS_PATH } from "routes/orderSeaFreight/constants";
import {
  listLineItemDeclarationsSuccess
} from "redux/model/lineItemDeclarations/lineItemDeclarations/lineItemDeclarationsActions";
import {
  listSeaFreightConsigneesSuccess
} from "redux/model/seaFreightConsignees/seaFreightConsignees/seaFreightConsigneesActions";
import {
  listSeaFreightExportersSuccess
} from "redux/model/seaFreightExporters/seaFreightExporters/seaFreightExportersActions";


function* orderDeclarationSaga() {
  yield all([
    fork(watchFetchOrderDeclaration),
    fork(watchUpdateOrderDeclaration),
    fork(watchCreateOrderDeclaration),
    fork(watchDeleteOrderDeclaration),
  ]);
}

// Fetch OrderDeclaration
export function* watchFetchOrderDeclaration() {
  yield takeEvery(FETCH_ORDER_DECLARATION, sagaFetchOrderDeclaration);
}

export function* sagaFetchOrderDeclaration({ payload }) {
  try {
    const response = yield call(apiFetchOrderDeclaration, payload);

    const { orderDeclaration, lineItemDeclarations, seaFreightConsignees, seaFreightExporters } = response.data;
    yield put(fetchOrderDeclarationSuccess({ orderDeclaration }));
    yield put(listLineItemDeclarationsSuccess({ lineItemDeclarations }));
    yield put(listSeaFreightConsigneesSuccess({ seaFreightConsignees }));
    yield put(listSeaFreightExportersSuccess({ seaFreightExporters }));

  } catch (e) {
    yield put(fetchOrderDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create OrderDeclaration
export function* watchCreateOrderDeclaration() {
  yield takeLeading(CREATE_ORDER_DECLARATION, sagaCreateOrderDeclaration);
}

export function* sagaCreateOrderDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateOrderDeclaration, payload);

    const { orderDeclaration } = response.data;
    yield put(createOrderDeclarationSuccess({ orderDeclaration }));
    yield put(toastSaved({ name: `OrderDeclaration` }));


    history.push(generateRoute(INDEX_ORDER_SEA_FREIGHTS_PATH));
    yield put(initOrderDeclaration());


  } catch (e) {
    yield put(createOrderDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update OrderDeclaration
export function* watchUpdateOrderDeclaration() {
  yield takeLeading(UPDATE_ORDER_DECLARATION, sagaUpdateOrderDeclaration);
}

export function* sagaUpdateOrderDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateOrderDeclaration, payload);

    const { orderDeclaration } = response.data;
    yield put(updateOrderDeclarationSuccess({ orderDeclaration }));
    yield put(toastSaved({ name: 'OrderDeclaration' }));
    history.push(generateRoute(INDEX_ORDER_SEA_FREIGHTS_PATH));
    yield put(initOrderDeclaration());

  } catch (e) {
    yield put(updateOrderDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete OrderDeclaration
export function* watchDeleteOrderDeclaration() {
  yield takeLeading(DELETE_ORDER_DECLARATION, sagaDeleteOrderDeclaration);
}

export function* sagaDeleteOrderDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteOrderDeclaration, payload);

    const { orderDeclaration } = response.data;
    yield put(deleteOrderDeclarationSuccess({ orderDeclaration }));
    yield put(toastDeleted({ name: 'OrderDeclaration' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteOrderDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default orderDeclarationSaga;
