export const INIT_OPTION_ITEMS = 'INIT_OPTION_ITEMS';

export const LIST_OPTION_ITEMS = 'LIST_OPTION_ITEMS';
export const LIST_OPTION_ITEMS_SUCCESS = 'LIST_OPTION_ITEMS_SUCCESS';
export const LIST_OPTION_ITEMS_FAILED = 'LIST_OPTION_ITEMS_FAILED';

export const NEW_OPTION_ITEM = 'NEW_OPTION_ITEM';

export const PULL_OPTION_ITEM_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS = 'PULL_OPTION_ITEM_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS';
export const PULL_OPTION_ITEM = 'PULL_OPTION_ITEM';

export const ASSIGN_OPTION_ITEM_TITLE_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS = 'ASSIGN_OPTION_ITEM_TITLE_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS'
export const ASSIGN_OPTION_ITEM_TITLE = 'ASSIGN_OPTION_ITEM_TITLE';
