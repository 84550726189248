import {
  ASSIGN_COLLECTION_TITLE,
  FETCH_COLLECTION,
  FETCH_COLLECTION_FAILED,
  FETCH_COLLECTION_SUCCESS,
  INIT_COLLECTION
} from "redux/model/collections/collection/constants";

const INIT_STATE = {
  collection: {},
  loading: true,
  error: null,
};

const collectionReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_COLLECTION:
      return INIT_STATE;

    case FETCH_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collection: action.payload.collection,
      };

    case FETCH_COLLECTION_FAILED:
      return {
        ...state,
        collection: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_COLLECTION_TITLE:
      return {
        ...state,
        collection: {
          ...state.collection,
          title: action.payload.title,
        }
      }

    default:
      return { ...state };
  }
};

export default collectionReducers;
