import {
  INIT_POINT_EXCHANGES,
  LIST_POINT_EXCHANGES,
  LIST_POINT_EXCHANGES_FAILED,
  LIST_POINT_EXCHANGES_SUCCESS
} from "redux/model/pointExchanges/pointExchanges/constants";

export const initPointExchanges = () => ({
  type: INIT_POINT_EXCHANGES,
  payload: {}
});

export const listPointExchanges = () => ({
  type: LIST_POINT_EXCHANGES,
  payload: {}
});

export const listPointExchangesSuccess = ({ pointExchanges }) => ({
  type: LIST_POINT_EXCHANGES_SUCCESS,
  payload: { pointExchanges }
});

export const listPointExchangesFailed = ({ error }) => ({
  type: LIST_POINT_EXCHANGES_FAILED,
  payload: { error }
});
