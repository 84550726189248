export const INIT_WECHAT_NOTICE = 'INIT_WECHAT_NOTICE';

export const FETCH_WECHAT_NOTICE = 'FETCH_WECHAT_NOTICE';
export const FETCH_WECHAT_NOTICE_SUCCESS = 'FETCH_WECHAT_NOTICE_SUCCESS';
export const FETCH_WECHAT_NOTICE_FAILED = 'FETCH_WECHAT_NOTICE_FAILED';

export const CREATE_WECHAT_NOTICE = 'CREATE_WECHAT_NOTICE';
export const CREATE_WECHAT_NOTICE_SUCCESS = 'CREATE_WECHAT_NOTICE_SUCCESS';
export const CREATE_WECHAT_NOTICE_FAILED = 'CREATE_WECHAT_NOTICE_FAILED';

export const UPDATE_WECHAT_NOTICE = 'UPDATE_WECHAT_NOTICE';
export const UPDATE_WECHAT_NOTICE_SUCCESS = 'UPDATE_WECHAT_NOTICE_SUCCESS';
export const UPDATE_WECHAT_NOTICE_FAILED = 'UPDATE_WECHAT_NOTICE_FAILED';

export const DELETE_WECHAT_NOTICE = 'DELETE_WECHAT_NOTICE';
export const DELETE_WECHAT_NOTICE_SUCCESS = 'DELETE_WECHAT_NOTICE_SUCCESS';
export const DELETE_WECHAT_NOTICE_FAILED = 'DELETE_WECHAT_NOTICE_FAILED';

export const ASSIGN_WECHAT_NOTICE_TITLE = 'ASSIGN_WECHAT_NOTICE_TITLE';
export const ASSIGN_WECHAT_NOTICE_DESCRIPTION = 'ASSIGN_WECHAT_NOTICE_DESCRIPTION';
export const ASSIGN_WECHAT_NOTICE_ACTIVE = 'ASSIGN_WECHAT_NOTICE_ACTIVE';