import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import {
  BASE_ORDER_PACK_PATH,
  BATCH_UPLOAD_ORDER_ATTACHMENTS_PATH,
  INDEX_ORDER_PACKS_PATH,
  SHOW_ORDER_PACK_PATH
} from "routes/orderPack/constants";

const OrderPacks = React.lazy(() => import('pages/orderPacks/index/OrderPacks'));
const ShowOrderPacks = React.lazy(() => import('pages/orderPacks/show/ShowOrderPack'));
const BatchUploadAttachments = React.lazy(() => import('pages/orderPacks/batchUploadAttachments/BatchUploadAttachments'));

export const orderPackRoutes = {
  path: BASE_ORDER_PACK_PATH,
  name: '小包订单列表',
  icon: 'far fa-box-open',
  children: [
    {
      path: INDEX_ORDER_PACKS_PATH,
      exact: true,
      name: '包裹订单',
      component: OrderPacks,
      route: PrivateRoute,
    },
    {
      path: BATCH_UPLOAD_ORDER_ATTACHMENTS_PATH,
      exact: true,
      name: '批量上传出库图片',
      component: BatchUploadAttachments,
      route: PrivateRoute,
    },
  ]
};

export const hiddenOrderPackRoutes = {
  children: [
    {
      path: SHOW_ORDER_PACK_PATH,
      exact: true,
      name: '包裹订单',
      component: ShowOrderPacks,
      route: PrivateRoute,
    },
  ]
}
