import {
  ASSIGN_OPTION_ITEM_TITLE, ASSIGN_OPTION_ITEM_TITLE_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
  INIT_OPTION_ITEMS,
  LIST_OPTION_ITEMS,
  LIST_OPTION_ITEMS_FAILED,
  LIST_OPTION_ITEMS_SUCCESS,
  NEW_OPTION_ITEM,
  PULL_OPTION_ITEM, PULL_OPTION_ITEM_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS
} from "redux/model/optionItems/optionItems/constants";

export const initOptionItems = () => ({
  type: INIT_OPTION_ITEMS,
  payload: {}
});

export const listOptionItems = () => ({
  type: LIST_OPTION_ITEMS,
  payload: {}
});

export const listOptionItemsSuccess = ({ optionItems }) => ({
  type: LIST_OPTION_ITEMS_SUCCESS,
  payload: { optionItems }
});

export const listOptionItemsFailed = ({ error }) => ({
  type: LIST_OPTION_ITEMS_FAILED,
  payload: { error }
});

export const newOptionItem = ({ dummyId, resource }) => ({
  type: NEW_OPTION_ITEM,
  payload: { dummyId, resource }
});

export const pullOptionItemSettleDownProductPackageMappers = ({
                                                                optionItem,
                                                                resource,
                                                                options,
                                                                optionItems,
                                                                productPackageMappers,
                                                                productPackages,
                                                              }) => ({
  type: PULL_OPTION_ITEM_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
  payload: {
    optionItem,
    resource,
    options,
    optionItems,
    productPackageMappers,
    productPackages,
  }
});

export const pullOptionItem = ({ optionItem, resource }) => ({
  type: PULL_OPTION_ITEM,
  payload: { optionItem, resource }
});

export const assignOptionItemTitleSettleDownProductPackageMappers = ({
                                                                       optionItem,
                                                                       title,
                                                                       options,
                                                                       optionItems,
                                                                       productPackageMappers,
                                                                       productPackages,
                                                                     }) => ({
  type: ASSIGN_OPTION_ITEM_TITLE_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
  payload: {
    optionItem,
    title,
    options,
    optionItems,
    productPackageMappers,
    productPackages,
  }
});

export const assignOptionItemTitle = ({ optionItem, title }) => ({
  type: ASSIGN_OPTION_ITEM_TITLE,
  payload: { optionItem, title }
});