
export const CREATE_POINT_EXCHANGE_API = {
  url: `/point_exchanges`,
  method: 'post'
};

export const INDEX_POINT_EXCHANGES_API = {
  url: `/point_exchanges`,
  method: 'get',
};

export const FETCH_POINT_EXCHANGE_API = {
  url: `/point_exchanges/:id`,
  method: 'get',
};

export const UPDATE_POINT_EXCHANGE_API = {
  url: `/point_exchanges/:id`,
  method: 'put'
};

export const DELETE_POINT_EXCHANGE_API = {
  url: `/point_exchanges/:id`,
  method: 'delete'
};
