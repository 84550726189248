import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LIST_PRODUCT_PACKAGE_MAPPERS,
  SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS
} from "redux/model/productPackageMappers/productPackageMappers/constants";
import { apiListProductPackageMappers } from "api/productPackageMapper/apiProductPackageMapper";
import {
  listProductPackageMappersFailed,
  listProductPackageMappersSuccess
} from "redux/model/productPackageMappers/productPackageMappers/productPackageMappersActions";
import { generateDummyId, generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";
import _ from "lodash";
import { cartesian } from "helpers/mathUtils";
import { toastError } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import {
  assignProductPackageMapperDestroy,
  newProductPackageMapper, pullProductPackageMapper
} from "redux/model/productPackageMappers/productPackageMapper/productPackageMapperActions";
import {
  assignProductPackageDestroy,
  assignProductPackageOptionItemTitles,
  pullProductPackage
} from "redux/model/productPackages/productPackage/productPackageActions";


function* productPackageMappersSaga() {
  yield all([
    fork(watchListProductPackageMappers),
    fork(watchSettleDownProductPackageMappers),
  ]);
}

// List ProductPackageMappers
export function* watchListProductPackageMappers() {
  yield takeEvery(LIST_PRODUCT_PACKAGE_MAPPERS, sagaListProductPackageMappers);
}

export function* sagaListProductPackageMappers() {
  try {
    const response = yield call(apiListProductPackageMappers);

    const { productPackageMappers } = response.data;

    yield put(listProductPackageMappersSuccess({ productPackageMappers }));

  } catch (e) {
    yield put(listProductPackageMappersFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// SettleDown ProductPackageMappers
export function* watchSettleDownProductPackageMappers() {
  yield takeEvery(SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS, sagaSettleDownProductPackageMappers);
}

export function* sagaSettleDownProductPackageMappers({ payload }) {
  try {
    let { optionItem, title, options, optionItems, productPackageMappers, productPackages } = payload;

    const optionItemsHash = _.mapKeys(optionItems, 'id');
    const updatedOptions = _.chain(Object.values(options))
      .map((option) => {
        const { optionItemIds } = option;

        return _.map(optionItemIds, (optionItemId) => {
          if (optionItem && optionItemId === optionItem.id) {
            return { ...optionItemsHash[optionItemId], title };
          }

          return optionItemsHash[optionItemId];
        })
      })
      .value();

    const cartesianResult = cartesian(...updatedOptions);

    // pull
    const updatedOptionItemIdsList = _.map(cartesianResult, (cartesianResultItem) => {
      const optionItems = _.isArray(cartesianResultItem) ? cartesianResultItem : [cartesianResultItem];
      return _.map(optionItems, 'id');
    });

    for (const productPackage of productPackages) {
      const needKeep = _.find(updatedOptionItemIdsList, (optionItemIds) => {
        return _.isEqual(_.sortBy(optionItemIds), _.sortBy(productPackage.optionItemIds))
      });

      if (!needKeep) {
        const productPackageMapperToBePulled = _.find(productPackageMappers, (productPackageMapper) => {
          return productPackageMapper.productPackageId === productPackage.id;
        });

        yield put(pullProductPackage({ productPackage }));
        productPackages = _.without(productPackages, productPackage);

        yield put(pullProductPackageMapper({ productPackageMapper: productPackageMapperToBePulled }));
        productPackageMappers = _.without(productPackageMappers, productPackageMapperToBePulled)
      }
    }

    // update or new
    const productPackagesHash = _.mapKeys(productPackages, 'id');
    for (const cartesianResultItem of cartesianResult) {
      const optionItems = _.isArray(cartesianResultItem) ? cartesianResultItem : [cartesianResultItem];

      const matchedProductPackageMapper = _.find(productPackageMappers, (productPackageMapper) => {
        const productPackage = productPackagesHash[productPackageMapper.productPackageId];

        return _.isEqual(_.sortBy(productPackage.optionItemIds), _.sortBy(_.map(optionItems, 'id')))
      });

      const matchedProductPackage = matchedProductPackageMapper
        ? productPackagesHash[matchedProductPackageMapper.productPackageId]
        : false;

      if (matchedProductPackage) {
        yield put(assignProductPackageMapperDestroy({productPackageMapper: matchedProductPackageMapper, destroy: false}));
        yield put(assignProductPackageDestroy({productPackage: matchedProductPackage, destroy: false}));

        const isTitlesRemained = _.isEqual(_.sortBy(matchedProductPackage.optionItemTitles), _.sortBy(_.map(optionItems, 'title')))

        if (!isTitlesRemained) {
          yield put(assignProductPackageOptionItemTitles({
            productPackage: matchedProductPackage,
            optionItemTitles: _.map(optionItems, 'title'),
          }));
        }

      } else {
        yield put(newProductPackageMapper({
          dummyId: generateDummyId('productPackageMapper'),
          optionItems,
        }));
      }
    }

  } catch (e) {
    yield put(toastError({}));

    throwFrontError(e);
  }
}


export default productPackageMappersSaga;
