import {
  INIT_COLLECTIONS,
  LIST_COLLECTIONS,
  LIST_COLLECTIONS_FAILED,
  LIST_COLLECTIONS_SUCCESS
} from "redux/model/collections/collections/constants";

export const initCollections = () => ({
  type: INIT_COLLECTIONS,
  payload: {}
});

export const listCollections = () => ({
  type: LIST_COLLECTIONS,
  payload: {}
});

export const listCollectionsSuccess = ({ collections }) => ({
  type: LIST_COLLECTIONS_SUCCESS,
  payload: { collections }
});

export const listCollectionsFailed = ({ error }) => ({
  type: LIST_COLLECTIONS_FAILED,
  payload: { error }
});
