
export const CREATE_ORDER_SEA_FREIGHT_API = {
  url: `/order/sea_freights`,
  method: 'post'
};

export const INDEX_ORDER_SEA_FREIGHTS_API = {
  url: `/order/sea_freights`,
  method: 'get',
};

export const FETCH_ORDER_SEA_FREIGHT_API = {
  url: `/order/sea_freights/:id`,
  method: 'get',
};

export const UPDATE_ORDER_SEA_FREIGHT_API = {
  url: `/order/sea_freights/:id`,
  method: 'put'
};

export const DELETE_ORDER_SEA_FREIGHT_API = {
  url: `/order/sea_freights/:id`,
  method: 'delete'
};

export const LIST_UNCONTAINED_ORDER_SEA_FREIGHTS_API = {
  url: `/order/sea_freights/list_uncontained`,
  method: 'post'
};

export const LIST_ORDER_SEA_FREIGHTS_CAM_BE_MERGED_API = {
  url: `/order/sea_freights/list_can_be_merged`,
  method: 'post',
};

export const SPLIT_ORDER_SEA_FREIGHT_API = {
  url: `/order/sea_freights/:id/split`,
  method: 'post',
};

export const MERGE_ORDER_SEA_FREIGHTS_API = {
  url: `/order/sea_freights/:id/merge`,
  method: 'post',
};

export const TRANSFER_ORDER_SEA_FREIGHTS_API = {
  url: `/order/sea_freights/:id/transfer`,
  method: 'post',
};

export const DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS_API = {
  url: `/order/sea_freights/download_processing_summary`,
  method: 'get',
  responseType: 'arraybuffer',
}

export const DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS_API = {
  url: `/order/sea_freights/download_dispatching_summary`,
  method: 'get',
  responseType: 'arraybuffer',
}