import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_PRODUCT_PATH, INDEX_PRODUCTS_PATH, NEW_PRODUCT_PATH, SHOW_PRODUCT_PATH } from "routes/product/constants";

const Products = React.lazy(() => import('pages/products/index/Products'));
const NewProduct = React.lazy(() => import('pages/products/new/NewProduct'));
const ShowProduct = React.lazy(() => import('pages/products/show/ShowProduct'));

export const productRoutes = {
  path: BASE_PRODUCT_PATH,
  name: '产品列表',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_PRODUCTS_PATH,
      exact: true,
      name: '产品',
      component: Products,
      route: PrivateRoute,
    },
    {
      path: NEW_PRODUCT_PATH,
      exact: true,
      name: '添加新品',
      component: NewProduct,
      route: PrivateRoute,
    },
  ],
};

export const hiddenProductRoutes = {
  children: [
    {
      path: SHOW_PRODUCT_PATH,
      exact: true,
      name: '产品展示',
      component: ShowProduct,
      route: PrivateRoute,
    }
  ]
}
