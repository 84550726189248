import {
  ASSIGN_ORDER_PACK_ADDITIONAL_FEE,
  ASSIGN_ORDER_PACK_BILLABLE_WEIGHT,
  ASSIGN_ORDER_PACK_DEDUCTION_AMOUNT, ASSIGN_ORDER_PACK_DISCOUNT,
  ASSIGN_ORDER_PACK_DISCOUNT_RATE,
  ASSIGN_ORDER_PACK_ESTIMATED_COST, ASSIGN_ORDER_PACK_EXPORT_DATE,
  ASSIGN_ORDER_PACK_GROSS_WEIGHT,
  ASSIGN_ORDER_PACK_HEIGHT,
  ASSIGN_ORDER_PACK_INTERNATIONAL_WAYBILL_NUMBER,
  ASSIGN_ORDER_PACK_LENGTH,
  ASSIGN_ORDER_PACK_NOTE, ASSIGN_ORDER_PACK_ORDER_FEE,
  ASSIGN_ORDER_PACK_QUOTATION,
  ASSIGN_ORDER_PACK_RECEIPT_ADDRESS,
  ASSIGN_ORDER_PACK_RECEIPT_CITY,
  ASSIGN_ORDER_PACK_RECEIPT_COUNTRY,
  ASSIGN_ORDER_PACK_RECEIPT_EMAIL,
  ASSIGN_ORDER_PACK_RECEIPT_MOBILE,
  ASSIGN_ORDER_PACK_RECEIPT_NAME,
  ASSIGN_ORDER_PACK_RECEIPT_POSTCODE,
  ASSIGN_ORDER_PACK_RECEIPT_STATE,
  ASSIGN_ORDER_PACK_STATUS,
  ASSIGN_ORDER_PACK_TITLE,
  ASSIGN_ORDER_PACK_TRACKING_NUMBER, ASSIGN_ORDER_PACK_TRANSPORT_METHOD,
  ASSIGN_ORDER_PACK_VOLUME_WEIGHT,
  ASSIGN_ORDER_PACK_WIDTH,
  CREATE_ORDER_PACK,
  CREATE_ORDER_PACK_FAILED,
  CREATE_ORDER_PACK_SUCCESS,
  DELETE_ORDER_PACK,
  DELETE_ORDER_PACK_FAILED,
  DELETE_ORDER_PACK_SUCCESS,
  FETCH_ORDER_PACK,
  FETCH_ORDER_PACK_FAILED,
  FETCH_ORDER_PACK_SUCCESS,
  INIT_ORDER_PACK,
  MERGE_ORDER_PACKS,
  SPLIT_ORDER_PACK,
  UPDATE_ORDER_PACK,
  UPDATE_ORDER_PACK_FAILED,
  UPDATE_ORDER_PACK_SUCCESS
} from "redux/model/orderPacks/orderPack/constants";

export const initOrderPack = () => ({
  type: INIT_ORDER_PACK,
  payload: {}
});

export const createOrderPack = ({ orderPack }) => ({
  type: CREATE_ORDER_PACK,
  payload: { orderPack }
});

export const createOrderPackSuccess = ({ orderPack }) => ({
  type: CREATE_ORDER_PACK_SUCCESS,
  payload: { orderPack }
});

export const createOrderPackFailed = ({ error }) => ({
  type: CREATE_ORDER_PACK_FAILED,
  payload: { error }
});

export const fetchOrderPack = ({ orderPackId }) => ({
  type: FETCH_ORDER_PACK,
  payload: { orderPackId }
});

export const fetchOrderPackSuccess = ({ orderPack }) => ({
  type: FETCH_ORDER_PACK_SUCCESS,
  payload: { orderPack }
});

export const fetchOrderPackFailed = ({ error }) => ({
  type: FETCH_ORDER_PACK_FAILED,
  payload: { error }
});

export const updateOrderPack = ({ orderPack }) => ({
  type: UPDATE_ORDER_PACK,
  payload: { orderPack }
});

export const updateOrderPackSuccess = ({ orderPack }) => ({
  type: UPDATE_ORDER_PACK_SUCCESS,
  payload: { orderPack }
});

export const updateOrderPackFailed = ({ error }) => ({
  type: UPDATE_ORDER_PACK_FAILED,
  payload: { error }
});

export const deleteOrderPack = ({ orderPack }) => ({
  type: DELETE_ORDER_PACK,
  payload: { orderPack }
});

export const deleteOrderPackSuccess = ({ orderPack }) => ({
  type: DELETE_ORDER_PACK_SUCCESS,
  payload: { orderPack }
});

export const deleteOrderPackFailed = ({ error }) => ({
  type: DELETE_ORDER_PACK_FAILED,
  payload: { error }
});

export const splitOrderPack = ({ order, lineItems }) => ({
  type: SPLIT_ORDER_PACK,
  payload: { order, lineItems }
});

export const mergeOrderPacks = ({ order, orderToBeMerged }) => ({
  type: MERGE_ORDER_PACKS,
  payload: { order, orderToBeMerged }
});

export const assignOrderPackReceiptName = ({ receiptName }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_NAME,
  payload: { receiptName }
});

export const assignOrderPackReceiptMobile = ({ receiptMobile }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_MOBILE,
  payload: { receiptMobile }
});

export const assignOrderPackReceiptEmail = ({ receiptEmail }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_EMAIL,
  payload: { receiptEmail }
});

export const assignOrderPackReceiptCountry = ({ receiptCountry }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_COUNTRY,
  payload: { receiptCountry }
});

export const assignOrderPackReceiptCity = ({ receiptCity }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_CITY,
  payload: { receiptCity }
});

export const assignOrderPackReceiptState = ({ receiptState }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_STATE,
  payload: { receiptState }
});

export const assignOrderPackReceiptAddress = ({ receiptAddress }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_ADDRESS,
  payload: { receiptAddress }
});

export const assignOrderPackReceiptPostcode = ({ receiptPostcode }) => ({
  type: ASSIGN_ORDER_PACK_RECEIPT_POSTCODE,
  payload: { receiptPostcode }
});

export const assignOrderPackNote = ({ note }) => ({
  type: ASSIGN_ORDER_PACK_NOTE,
  payload: { note }
});

export const assignOrderPackTitle = ({ title }) => ({
  type: ASSIGN_ORDER_PACK_TITLE,
  payload: { title }
});

export const assignOrderPackLength = ({ length }) => ({
  type: ASSIGN_ORDER_PACK_LENGTH,
  payload: { length }
});

export const assignOrderPackWidth = ({ width }) => ({
  type: ASSIGN_ORDER_PACK_WIDTH,
  payload: { width }
});

export const assignOrderPackHeight = ({ height }) => ({
  type: ASSIGN_ORDER_PACK_HEIGHT,
  payload: { height }
});

export const assignOrderPackGrossWeight = ({ grossWeight }) => ({
  type: ASSIGN_ORDER_PACK_GROSS_WEIGHT,
  payload: { grossWeight }
});

export const assignOrderPackStatus = ({ status }) => ({
  type: ASSIGN_ORDER_PACK_STATUS,
  payload: { status }
});

export const assignOrderPackTrackingNumber = ({ trackingNumber }) => ({
  type: ASSIGN_ORDER_PACK_TRACKING_NUMBER,
  payload: { trackingNumber }
});

export const assignOrderPackInternationalWaybillNumber = ({ internationalWaybillNumber }) => ({
  type: ASSIGN_ORDER_PACK_INTERNATIONAL_WAYBILL_NUMBER,
  payload: { internationalWaybillNumber }
});

export const assignOrderPackVolumeWeight = ({ volumeWeight }) => ({
  type: ASSIGN_ORDER_PACK_VOLUME_WEIGHT,
  payload: { volumeWeight }
});

export const assignOrderPackBillableWeight = ({ billableWeight }) => ({
  type: ASSIGN_ORDER_PACK_BILLABLE_WEIGHT,
  payload: { billableWeight }
});

export const assignOrderPackDiscountRate = ({ discountRate }) => ({
  type: ASSIGN_ORDER_PACK_DISCOUNT_RATE,
  payload: { discountRate }
});

export const assignOrderPackDeductionAmount = ({ deductionAmount }) => ({
  type: ASSIGN_ORDER_PACK_DEDUCTION_AMOUNT,
  payload: { deductionAmount }
});

export const assignOrderPackQuotation = ({ quotation }) => ({
  type: ASSIGN_ORDER_PACK_QUOTATION,
  payload: { quotation }
});

export const assignOrderPackEstimatedCost = ({ estimatedCost }) => ({
  type: ASSIGN_ORDER_PACK_ESTIMATED_COST,
  payload: { estimatedCost }
});

export const assignOrderPackOrderFee = ({ orderFee }) => ({
  type: ASSIGN_ORDER_PACK_ORDER_FEE,
  payload: { orderFee }
});

export const assignOrderPackExportDate = ({ exportDate }) => ({
  type: ASSIGN_ORDER_PACK_EXPORT_DATE,
  payload: { exportDate }
});

export const assignOrderPackDiscount = ({ discount }) => ({
  type: ASSIGN_ORDER_PACK_DISCOUNT,
  payload: { discount }
});

export const assignOrderPackAdditionalFee = ({ additionalFee }) => ({
  type: ASSIGN_ORDER_PACK_ADDITIONAL_FEE,
  payload: { additionalFee }
});

export const assignOrderPackTransportMethod = ({ transportMethod }) => ({
  type: ASSIGN_ORDER_PACK_TRANSPORT_METHOD,
  payload: { transportMethod }
});
