import _ from 'lodash';
import {
  ASSIGN_OPTION_ITEM_TITLE,
  INIT_OPTION_ITEMS,
  LIST_OPTION_ITEMS,
  LIST_OPTION_ITEMS_FAILED,
  LIST_OPTION_ITEMS_SUCCESS,
  NEW_OPTION_ITEM,
  PULL_OPTION_ITEM
} from "redux/model/optionItems/optionItems/constants";
import { isNew } from "@tmatt-tech/react_common";

const INIT_STATE = {
  optionItems: {},
  loading: true,
  error: null,
};

const optionItemsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_OPTION_ITEMS:
      return INIT_STATE;

    case LIST_OPTION_ITEMS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_OPTION_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        optionItems: _.mapKeys(action.payload.optionItems, 'id'),
      };

    case LIST_OPTION_ITEMS_FAILED:
      return {
        ...state,
        optionItems: {},
        loading: false,
        error: action.payload.error,
      };

    case NEW_OPTION_ITEM:
      return {
        ...state,
        optionItems: {
          ...state.optionItems,
          [action.payload.dummyId]: {
            id: action.payload.dummyId,
            title: '',
            destroy: false,
          }
        }
      };

    case PULL_OPTION_ITEM:
      if (isNew(action.payload.optionItem)) {
        return {
          ...state,
          optionItems: _.omit(state.optionItems, action.payload.optionItem.id),
        };
      }

      return {
        ...state,
        optionItems: {
          ...state.optionItems,
          [action.payload.optionItem.id]: {
            ...state.optionItems[action.payload.optionItem.id],
            destroy: true,
          },
        },
      };

    case ASSIGN_OPTION_ITEM_TITLE:
      return {
        ...state,
        optionItems: {
          ...state.optionItems,
          [action.payload.optionItem.id]: {
            ...state.optionItems[action.payload.optionItem.id],
            title: action.payload.title,
          },
        },
      };

    default:
      return { ...state };
  }
};

export default optionItemsReducers;
