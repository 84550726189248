export const INIT_PRODUCT_PACKAGE_MAPPER = 'INIT_PRODUCT_PACKAGE_MAPPER';

export const FETCH_PRODUCT_PACKAGE_MAPPER = 'FETCH_PRODUCT_PACKAGE_MAPPER';
export const FETCH_PRODUCT_PACKAGE_MAPPER_SUCCESS = 'FETCH_PRODUCT_PACKAGE_MAPPER_SUCCESS';
export const FETCH_PRODUCT_PACKAGE_MAPPER_FAILED = 'FETCH_PRODUCT_PACKAGE_MAPPER_FAILED';

export const CREATE_PRODUCT_PACKAGE_MAPPER = 'CREATE_PRODUCT_PACKAGE_MAPPER';
export const CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS = 'CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS';
export const CREATE_PRODUCT_PACKAGE_MAPPER_FAILED = 'CREATE_PRODUCT_PACKAGE_MAPPER_FAILED';

export const UPDATE_PRODUCT_PACKAGE_MAPPER = 'UPDATE_PRODUCT_PACKAGE_MAPPER';
export const UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS = 'UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS';
export const UPDATE_PRODUCT_PACKAGE_MAPPER_FAILED = 'UPDATE_PRODUCT_PACKAGE_MAPPER_FAILED';

export const DELETE_PRODUCT_PACKAGE_MAPPER = 'DELETE_PRODUCT_PACKAGE_MAPPER';
export const DELETE_PRODUCT_PACKAGE_MAPPER_SUCCESS = 'DELETE_PRODUCT_PACKAGE_MAPPER_SUCCESS';
export const DELETE_PRODUCT_PACKAGE_MAPPER_FAILED = 'DELETE_PRODUCT_PACKAGE_MAPPER_FAILED';

export const NEW_PRODUCT_PACKAGE_MAPPER = 'NEW_PRODUCT_PACKAGE_MAPPER';
export const PULL_PRODUCT_PACKAGE_MAPPER = 'PULL_PRODUCT_PACKAGE_MAPPER';

export const ASSIGN_PRODUCT_PACKAGE_MAPPER_DESTROY = 'ASSIGN_PRODUCT_PACKAGE_MAPPER_DESTROY';