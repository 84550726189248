
export const CREATE_PRODUCT_PACKAGE_MAPPER_API = {
  url: `/productPackageMappers`,
  method: 'post'
};

export const INDEX_PRODUCT_PACKAGE_MAPPERS_API = {
  url: `/productPackageMappers`,
  method: 'get',
};

export const FETCH_PRODUCT_PACKAGE_MAPPER_API = {
  url: `/productPackageMappers/:id`,
  method: 'get',
};

export const UPDATE_PRODUCT_PACKAGE_MAPPER_API = {
  url: `/productPackageMappers/:id`,
  method: 'put'
};

export const DELETE_PRODUCT_PACKAGE_MAPPER_API = {
  url: `/productPackageMappers/:id`,
  method: 'delete'
};
