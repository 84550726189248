export const INIT_COLLECTION = 'INIT_COLLECTION';

export const FETCH_COLLECTION = 'FETCH_COLLECTION';
export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_FAILED = 'FETCH_COLLECTION_FAILED';

export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_FAILED = 'CREATE_COLLECTION_FAILED';

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_FAILED = 'UPDATE_COLLECTION_FAILED';

export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_FAILED = 'DELETE_COLLECTION_FAILED';

export const ASSIGN_COLLECTION_TITLE = 'ASSIGN_COLLECTION_TITLE';
