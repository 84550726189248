import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import {
  INDEX_PAYMENT_TRANSACTION_PATH,
  INDEX_TRANSACTIONS_PATH,
  NEW_TRANSACTION_PATH
} from "routes/transaction/constants";

const Transactions = React.lazy(() => import('pages/transactions/index/Transactions'));
const PaymentTransactions = React.lazy(() => import('pages/transactions/index/PaymentTransactions'));
const NewTransaction = React.lazy(() => import('pages/transactions/new/NewTransaction'));
const ShowTransaction = React.lazy(() => import('pages/transactions/show/ShowTransaction'));

export const transactionRoutes = {
  name: '付款审批',
  icon: 'far fa-star',
  route: PrivateRoute,
  children: [
    {
      path: INDEX_TRANSACTIONS_PATH,
      name: '预付款审批',
      exact: true,
      component: Transactions,
      route: PrivateRoute,
    },
    {
      path: INDEX_PAYMENT_TRANSACTION_PATH,
      exact: true,
      name: '订单支付审批',
      component: PaymentTransactions,
      route: PrivateRoute,
    },
  ],
};

export const hiddenTransactionRoutes = {
  children: [
    {
      path: NEW_TRANSACTION_PATH,
      exact: true,
      name: 'Add New',
      component: NewTransaction,
      route: PrivateRoute,
    },

    // {
    //   path: SHOW_TRANSACTION_PATH,
    //   exact: true,
    //   name: 'Show Transaction',
    //   component: ShowTransaction,
    //   route: PrivateRoute,
    // }
  ]
}
