export const INIT_ORDER_PACK = 'INIT_ORDER_PACK';

export const FETCH_ORDER_PACK = 'FETCH_ORDER_PACK';
export const FETCH_ORDER_PACK_SUCCESS = 'FETCH_ORDER_PACK_SUCCESS';
export const FETCH_ORDER_PACK_FAILED = 'FETCH_ORDER_PACK_FAILED';

export const CREATE_ORDER_PACK = 'CREATE_ORDER_PACK';
export const CREATE_ORDER_PACK_SUCCESS = 'CREATE_ORDER_PACK_SUCCESS';
export const CREATE_ORDER_PACK_FAILED = 'CREATE_ORDER_PACK_FAILED';

export const UPDATE_ORDER_PACK = 'UPDATE_ORDER_PACK';
export const UPDATE_ORDER_PACK_SUCCESS = 'UPDATE_ORDER_PACK_SUCCESS';
export const UPDATE_ORDER_PACK_FAILED = 'UPDATE_ORDER_PACK_FAILED';

export const DELETE_ORDER_PACK = 'DELETE_ORDER_PACK';
export const DELETE_ORDER_PACK_SUCCESS = 'DELETE_ORDER_PACK_SUCCESS';
export const DELETE_ORDER_PACK_FAILED = 'DELETE_ORDER_PACK_FAILED';

export const SPLIT_ORDER_PACK = 'SPLIT_ORDER_PACK';

export const MERGE_ORDER_PACKS = 'MERGE_ORDER_PACKS';

export const ASSIGN_ORDER_PACK_RECEIPT_NAME = 'ASSIGN_ORDER_PACK_RECEIPT_NAME';
export const ASSIGN_ORDER_PACK_RECEIPT_MOBILE = 'ASSIGN_ORDER_PACK_RECEIPT_MOBILE';
export const ASSIGN_ORDER_PACK_RECEIPT_EMAIL = 'ASSIGN_ORDER_PACK_RECEIPT_EMAIL';
export const ASSIGN_ORDER_PACK_RECEIPT_COUNTRY = 'ASSIGN_ORDER_PACK_RECEIPT_COUNTRY';
export const ASSIGN_ORDER_PACK_RECEIPT_CITY = 'ASSIGN_ORDER_PACK_RECEIPT_CITY';
export const ASSIGN_ORDER_PACK_RECEIPT_STATE = 'ASSIGN_ORDER_PACK_RECEIPT_STATE';
export const ASSIGN_ORDER_PACK_RECEIPT_ADDRESS = 'ASSIGN_ORDER_PACK_RECEIPT_ADDRESS';
export const ASSIGN_ORDER_PACK_RECEIPT_POSTCODE = 'ASSIGN_ORDER_PACK_RECEIPT_POSTCODE';
export const ASSIGN_ORDER_PACK_NOTE = 'ASSIGN_ORDER_PACK_NOTE';
export const ASSIGN_ORDER_PACK_TITLE = 'ASSIGN_ORDER_PACK_TITLE';
export const ASSIGN_ORDER_PACK_LENGTH = 'ASSIGN_ORDER_PACK_LENGTH';
export const ASSIGN_ORDER_PACK_WIDTH = 'ASSIGN_ORDER_PACK_WIDTH';
export const ASSIGN_ORDER_PACK_HEIGHT = 'ASSIGN_ORDER_PACK_HEIGHT';
export const ASSIGN_ORDER_PACK_GROSS_WEIGHT = 'ASSIGN_ORDER_PACK_GROSS_WEIGHT';
export const ASSIGN_ORDER_PACK_STATUS = 'ASSIGN_ORDER_PACK_STATUS';
export const ASSIGN_ORDER_PACK_DISCOUNT = 'ASSIGN_ORDER_PACK_DISCOUNT';
export const ASSIGN_ORDER_PACK_ADDITIONAL_FEE = 'ASSIGN_ORDER_PACK_ADDITIONAL_FEE';

export const ASSIGN_ORDER_PACK_TRACKING_NUMBER = 'ASSIGN_ORDER_PACK_TRACKING_NUMBER';
export const ASSIGN_ORDER_PACK_INTERNATIONAL_WAYBILL_NUMBER = 'ASSIGN_ORDER_PACK_INTERNATIONAL_WAYBILL_NUMBER';
export const ASSIGN_ORDER_PACK_VOLUME_WEIGHT = 'ASSIGN_ORDER_PACK_VOLUME_WEIGHT';
export const ASSIGN_ORDER_PACK_BILLABLE_WEIGHT = 'ASSIGN_ORDER_PACK_BILLABLE_WEIGHT';
export const ASSIGN_ORDER_PACK_DISCOUNT_RATE = 'ASSIGN_ORDER_PACK_DISCOUNT_RATE';
export const ASSIGN_ORDER_PACK_DEDUCTION_AMOUNT = 'ASSIGN_ORDER_PACK_DEDUCTION_AMOUNT';
export const ASSIGN_ORDER_PACK_QUOTATION = 'ASSIGN_ORDER_PACK_QUOTATION';
export const ASSIGN_ORDER_PACK_ESTIMATED_COST = 'ASSIGN_ORDER_PACK_ESTIMATED_COST';
export const ASSIGN_ORDER_PACK_ORDER_FEE = 'ASSIGN_ORDER_PACK_ORDER_FEE';
export const ASSIGN_ORDER_PACK_EXPORT_DATE = 'ASSIGN_ORDER_PACK_EXPORT_DATE';
export const ASSIGN_ORDER_PACK_TRANSPORT_METHOD = 'ASSIGN_ORDER_PACK_TRANSPORT_METHOD';
