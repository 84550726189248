import {
  INIT_PRODUCTS,
  LIST_PRODUCTS,
  LIST_PRODUCTS_FAILED,
  LIST_PRODUCTS_SUCCESS
} from "redux/model/products/products/constants";

export const initProducts = () => ({
  type: INIT_PRODUCTS,
  payload: {}
});

export const listProducts = () => ({
  type: LIST_PRODUCTS,
  payload: {}
});

export const listProductsSuccess = ({ products }) => ({
  type: LIST_PRODUCTS_SUCCESS,
  payload: { products }
});

export const listProductsFailed = ({ error }) => ({
  type: LIST_PRODUCTS_FAILED,
  payload: { error }
});
