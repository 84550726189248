import _ from 'lodash';
import {
  CREATE_POINT_EXCHANGE_SUCCESS,
  DELETE_POINT_EXCHANGE_SUCCESS,
  UPDATE_POINT_EXCHANGE_SUCCESS
} from "redux/model/pointExchanges/pointExchange/constants";
import {
  INIT_POINT_EXCHANGES,
  LIST_POINT_EXCHANGES,
  LIST_POINT_EXCHANGES_FAILED,
  LIST_POINT_EXCHANGES_SUCCESS
} from "redux/model/pointExchanges/pointExchanges/constants";

const INIT_STATE = {
  pointExchanges: {},
  loading: true,
  error: null,
};

const pointExchangesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_POINT_EXCHANGES:
      return INIT_STATE;

    case LIST_POINT_EXCHANGES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_POINT_EXCHANGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pointExchanges: _.mapKeys(action.payload.pointExchanges, 'id'),
      };

    case LIST_POINT_EXCHANGES_FAILED:
      return {
        ...state,
        pointExchanges: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_POINT_EXCHANGE_SUCCESS:
      return {
        ...state,
        pointExchanges: {
          [action.payload.pointExchange.id]: action.payload.pointExchange,
          ...state.pointExchanges,
        },
      };

    case UPDATE_POINT_EXCHANGE_SUCCESS:
      return {
        ...state,
        pointExchanges: {
          [action.payload.pointExchange.id]: action.payload.pointExchange,
        },
      };

    case DELETE_POINT_EXCHANGE_SUCCESS:
      return {
        ...state,
        pointExchanges: _.omit(state.pointExchanges, action.payload.pointExchange.id)
      };

    default:
      return { ...state };
  }
};

export default pointExchangesReducers;
