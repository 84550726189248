import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_POINT_EXCHANGES } from "redux/model/pointExchanges/pointExchanges/constants";
import { apiListPointExchanges } from "api/pointExchange/apiPointExchange";
import { listPointExchangesFailed, listPointExchangesSuccess } from "redux/model/pointExchanges/pointExchanges/pointExchangesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* pointExchangesSaga() {
  yield all([
    fork(watchListPointExchanges),
  ]);
}

// List PointExchanges
export function* watchListPointExchanges() {
  yield takeEvery(LIST_POINT_EXCHANGES, sagaListPointExchanges);
}

export function* sagaListPointExchanges() {
  try {
    const response = yield call(apiListPointExchanges);

    const { pointExchanges } = response.data;

    yield put(listPointExchangesSuccess({ pointExchanges }));

  } catch (e) {
    yield put(listPointExchangesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default pointExchangesSaga;
