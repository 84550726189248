import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_COUPON_RULE_PATH, INDEX_COUPON_RULES_PATH, NEW_COUPON_RULE_PATH, SHOW_COUPON_RULE_PATH } from "routes/couponRule/constants";

const CouponRules = React.lazy(() => import('pages/couponRules/index/CouponRules'));
const NewCouponRule = React.lazy(() => import('pages/couponRules/new/NewCouponRule'));
const ShowCouponRule = React.lazy(() => import('pages/couponRules/show/ShowCouponRule'));

export const couponRuleRoutes = {
  path: BASE_COUPON_RULE_PATH,
  name: '优惠券规则',
  icon: 'far fa-ticket',
  children: [
    {
      path: INDEX_COUPON_RULES_PATH,
      exact: true,
      name: '列表',
      component: CouponRules,
      route: PrivateRoute,
    },
    {
      path: NEW_COUPON_RULE_PATH,
      exact: true,
      name: '添加规则',
      component: NewCouponRule,
      route: PrivateRoute,
    },
  ],
};

export const hiddenCouponRuleRoutes = {
  children: [
    {
      path: SHOW_COUPON_RULE_PATH,
      exact: true,
      name: 'Show CouponRule',
      component: ShowCouponRule,
      route: PrivateRoute,
    }
  ]
}
