import _ from 'lodash';
import {
  CREATE_ATTACHMENT_LIST_SUCCESS,
  DELETE_ATTACHMENT_LIST_SUCCESS,
  UPDATE_ATTACHMENT_LIST_SUCCESS
} from "redux/model/attachmentLists/attachmentList/constants";
import {
  CREATE_ATTACHMENT_LIST_ATTACHMENT,
  CREATE_ATTACHMENT_LIST_ATTACHMENT_SUCCESS,
  INIT_ATTACHMENT_LISTS,
  LIST_ATTACHMENT_LISTS,
  LIST_ATTACHMENT_LISTS_FAILED,
  LIST_ATTACHMENT_LISTS_SUCCESS
} from "redux/model/attachmentLists/attachmentLists/constants";
import { MOVE_LINE_ATTACHMENT } from "redux/model/lineAttachments/lineAttachments/constants";

const INIT_STATE = {
  attachmentLists: {},
  loading: true,
  error: null,
};

const attachmentListsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ATTACHMENT_LISTS:
      return INIT_STATE;

    case LIST_ATTACHMENT_LISTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_ATTACHMENT_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        attachmentLists: _.mapKeys(action.payload.attachmentLists, 'id'),
      };

    case LIST_ATTACHMENT_LISTS_FAILED:
      return {
        ...state,
        attachmentLists: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_ATTACHMENT_LIST_SUCCESS:
      return {
        ...state,
        attachmentLists: {
          [action.payload.attachmentList.id]: action.payload.attachmentList,
          ...state.attachmentLists,
        },
      };

    case UPDATE_ATTACHMENT_LIST_SUCCESS:
      return {
        ...state,
        attachmentLists: {
          ...state.attachmentLists,
          [action.payload.attachmentList.id]: action.payload.attachmentList,
        },
      };

    case DELETE_ATTACHMENT_LIST_SUCCESS:
      return {
        ...state,
        attachmentLists: _.omit(state.attachmentLists, action.payload.attachmentList.id)
      };

    case CREATE_ATTACHMENT_LIST_ATTACHMENT:
      return {
        ...state,
        attachmentLists: {
          ...state.attachmentLists,
          [action.payload.attachmentList.id]: {
            ...state.attachmentLists[action.payload.attachmentList.id],
            lineAttachmentIds: [
              ...state.attachmentLists[action.payload.attachmentList.id].lineAttachmentIds,
              action.payload.lineAttachment.dummyId,
            ],
          },
        },
      };

    case CREATE_ATTACHMENT_LIST_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachmentLists: {
          ...state.attachmentLists,
          [action.payload.attachmentList.id]: {
            ...state.attachmentLists[action.payload.attachmentList.id],
            lineAttachmentIds: _.without(
              [
                ...state.attachmentLists[action.payload.attachmentList.id].lineAttachmentIds,
                action.payload.lineAttachment.id,
              ],
              action.payload.lineAttachment.dummyId
            ),
          },
        },
      };

    case MOVE_LINE_ATTACHMENT:
      const {
        destination: lineAttachmentDestination,
        source: lineAttachmentSource,
        draggableId: lineAttachmentDraggableId
      } = action.payload.draggableResult;

      const { droppableId: lineAttachmentDestinationId } = lineAttachmentDestination;

      if (_.includes(state.attachmentLists[lineAttachmentDestinationId].lineAttachmentIds, lineAttachmentDraggableId)) {
        const newLineAttachmentIds = Array.from(state.attachmentLists[lineAttachmentDestinationId].lineAttachmentIds);
        newLineAttachmentIds.splice(lineAttachmentSource.index, 1);
        newLineAttachmentIds.splice(lineAttachmentDestination.index, 0, lineAttachmentDraggableId);

        return {
          ...state,
          attachmentLists: {
            ...state.attachmentLists,
            [lineAttachmentDestinationId]: {
              ...state.attachmentLists[lineAttachmentDestinationId],
              lineAttachmentIds: newLineAttachmentIds,
            },
          },
        };
      }

      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export default attachmentListsReducers;
