import { combineReducers } from 'redux';
import packBatchUploadReducers from "redux/model/packBatchUploads/packBatchUpload/packBatchUploadReducers";
import orderPacksReducers from "redux/model/orderPacks/orderPacks/orderPacksReducers";
import clientReducers from "./clients/client/clientReducers";
import clientsReducers from "./clients/clients/clientsReducers";
import packReducers from "./packs/pack/packReducers";
import packsReducers from "./packs/packs/packsReducers";
import orderPackReducers from "redux/model/orderPacks/orderPack/orderPackReducers";
import lineItemReducers from "redux/model/lineItems/lineItem/lineItemReducers";
import lineItemsReducers from "redux/model/lineItems/lineItems/lineItemsReducers";
import exportDimensionBatchUploadReducers
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/exportDimensionBatchUploadReducers";
import exportDimensionBatchUploadsReducers
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUploads/exportDimensionBatchUploadsReducers";
import exportInternationalBatchUploadReducers
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/exportInternationalBatchUploadReducers";
import exportInternationalBatchUploadsReducers
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/exportInternationalBatchUploadsReducers";
import lineAttachmentReducers from "redux/model/lineAttachments/lineAttachment/lineAttachmentReducers";
import lineAttachmentsReducers from "redux/model/lineAttachments/lineAttachments/lineAttachmentsReducers";
import transactionReducers from "redux/model/transactions/transaction/transactionReducers";
import transactionsReducers from "redux/model/transactions/transactions/transactionsReducers";
import receivedNoticeReducers from "redux/model/receivedNotices/receivedNotice/receivedNoticeReducers";
import receivedNoticesReducers from "redux/model/receivedNotices/receivedNotices/receivedNoticesReducers";
import paymentReducers from "redux/model/payments/payment/paymentReducers";
import paymentsReducers from "redux/model/payments/payments/paymentsReducers";
import couponRuleReducers from "redux/model/couponRules/couponRule/couponRuleReducers";
import couponRulesReducers from "redux/model/couponRules/couponRules/couponRulesReducers";
import wechatNoticeReducers from "redux/model/wechatNotices/wechatNotice/wechatNoticeReducers";
import wechatNoticesReducers from "redux/model/wechatNotices/wechatNotices/wechatNoticesReducers";
import receiptAddressReducers from "redux/model/receiptAddresses/receiptAddress/receiptAddressReducers";
import receiptAddressesReducers from "redux/model/receiptAddresses/receiptAddresses/receiptAddressesReducers";
import productReducers from "redux/model/products/product/productReducers";
import productsReducers from "redux/model/products/products/productsReducers";
import collectionReducers from "redux/model/collections/collection/collectionReducers";
import collectionsReducers from "redux/model/collections/collections/collectionsReducers";
import optionsReducers from "redux/model/options/options/optionsReducers";
import optionItemsReducers from "redux/model/optionItems/optionItems/optionItemsReducers";
import attachmentListsReducers from "redux/model/attachmentLists/attachmentLists/attachmentListsReducers";
import orderSeaFreightReducers from "redux/model/orderSeaFreights/orderSeaFreight/orderSeaFreightReducers";
import orderSeaFreightsReducers from "redux/model/orderSeaFreights/orderSeaFreights/orderSeaFreightsReducers";
import seaFreightContainerReducers
  from "redux/model/seaFreightContainers/seaFreightContainer/seaFreightContainerReducers";
import seaFreightContainersReducers
  from "redux/model/seaFreightContainers/seaFreightContainers/seaFreightContainersReducers";
import orderDeclarationReducers from "redux/model/orderDeclarations/orderDeclaration/orderDeclarationReducers";
import orderDeclarationsReducers from "redux/model/orderDeclarations/orderDeclarations/orderDeclarationsReducers";
import lineItemDeclarationReducers
  from "redux/model/lineItemDeclarations/lineItemDeclaration/lineItemDeclarationReducers";
import lineItemDeclarationsReducers
  from "redux/model/lineItemDeclarations/lineItemDeclarations/lineItemDeclarationsReducers";
import seaFreightContainerDeclarationReducers
  from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/seaFreightContainerDeclarationReducers";
import seaFreightContainerDeclarationsReducers
  from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclarations/seaFreightContainerDeclarationsReducers";
import productPackageMapperReducers
  from "redux/model/productPackageMappers/productPackageMapper/productPackageMapperReducers";
import productPackageMappersReducers
  from "redux/model/productPackageMappers/productPackageMappers/productPackageMappersReducers";
import productPackageReducers from "redux/model/productPackages/productPackage/productPackageReducers";
import productPackagesReducers from "redux/model/productPackages/productPackages/productPackagesReducers";
import seaFreightConsigneeReducers
  from "redux/model/seaFreightConsignees/seaFreightConsignee/seaFreightConsigneeReducers";
import seaFreightConsigneesReducers
  from "redux/model/seaFreightConsignees/seaFreightConsignees/seaFreightConsigneesReducers";
import seaFreightExporterReducers from "redux/model/seaFreightExporters/seaFreightExporter/seaFreightExporterReducers";
import seaFreightExportersReducers
  from "redux/model/seaFreightExporters/seaFreightExporters/seaFreightExportersReducers";
import pointExchangeReducers from "redux/model/pointExchanges/pointExchange/pointExchangeReducers";
import pointExchangesReducers from "redux/model/pointExchanges/pointExchanges/pointExchangesReducers";
import currencyExchangeReducers from "redux/model/currencyExchanges/currencyExchange/currencyExchangeReducers";
import currencyExchangesReducers from "redux/model/currencyExchanges/currencyExchanges/currencyExchangesReducers";


export default combineReducers({
  packBatchUpload: packBatchUploadReducers,
  orderPack: orderPackReducers,
  orderPacks: orderPacksReducers,
  client: clientReducers,
  clients: clientsReducers,
  pack: packReducers,
  packs: packsReducers,
  lineItem: lineItemReducers,
  lineItems: lineItemsReducers,
  exportDimensionBatchUpload: exportDimensionBatchUploadReducers,
  exportDimensionBatchUploads: exportDimensionBatchUploadsReducers,
  exportInternationalBatchUpload: exportInternationalBatchUploadReducers,
  exportInternationalBatchUploads: exportInternationalBatchUploadsReducers,
  lineAttachment: lineAttachmentReducers,
  lineAttachments: lineAttachmentsReducers,
  transaction: transactionReducers,
  transactions: transactionsReducers,
  receivedNotice: receivedNoticeReducers,
  receivedNotices: receivedNoticesReducers,
  payment: paymentReducers,
  payments: paymentsReducers,
  couponRule: couponRuleReducers,
  couponRules: couponRulesReducers,
  wechatNotice: wechatNoticeReducers,
  wechatNotices: wechatNoticesReducers,
  receiptAddress: receiptAddressReducers,
  receiptAddresses: receiptAddressesReducers,
  product: productReducers,
  products: productsReducers,
  collection: collectionReducers,
  collections: collectionsReducers,
  options: optionsReducers,
  optionItems: optionItemsReducers,
  attachmentLists: attachmentListsReducers,
  orderSeaFreight: orderSeaFreightReducers,
  orderSeaFreights: orderSeaFreightsReducers,
  seaFreightContainer: seaFreightContainerReducers,
  seaFreightContainers: seaFreightContainersReducers,
  orderDeclaration: orderDeclarationReducers,
  orderDeclarations: orderDeclarationsReducers,
  lineItemDeclaration: lineItemDeclarationReducers,
  lineItemDeclarations: lineItemDeclarationsReducers,
  seaFreightContainerDeclaration: seaFreightContainerDeclarationReducers,
  seaFreightContainerDeclarations: seaFreightContainerDeclarationsReducers,
  productPackageMapper: productPackageMapperReducers,
  productPackageMappers: productPackageMappersReducers,
  productPackage: productPackageReducers,
  productPackages: productPackagesReducers,
  seaFreightConsignee: seaFreightConsigneeReducers,
  seaFreightConsignees: seaFreightConsigneesReducers,
  seaFreightExporter: seaFreightExporterReducers,
  seaFreightExporters: seaFreightExportersReducers,
  pointExchange: pointExchangeReducers,
  pointExchanges: pointExchangesReducers,
  currencyExchange: currencyExchangeReducers,
  currencyExchanges: currencyExchangesReducers,
});
