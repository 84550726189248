import {
  ASSIGN_PRODUCT_COLLECTIONS, ASSIGN_PRODUCT_COMPARED_AT_PRICE,
  ASSIGN_PRODUCT_DESCRIPTION, ASSIGN_PRODUCT_PRICE,
  ASSIGN_PRODUCT_TITLE, ASSIGN_PRODUCT_VENDOR, FETCH_NEW_PRODUCT,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCESS,
  INIT_PRODUCT
} from "redux/model/products/product/constants";
import _ from 'lodash';
import { NEW_OPTION, PULL_OPTION } from "redux/model/options/options/constants";
import { isNew } from "@tmatt-tech/react_common";
import {
  NEW_PRODUCT_PACKAGE_MAPPER,
  PULL_PRODUCT_PACKAGE_MAPPER
} from "redux/model/productPackageMappers/productPackageMapper/constants";

const INIT_STATE = {
  product: {},
  loading: true,
  error: null,
};

const productReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT:
      return INIT_STATE;

    case FETCH_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_NEW_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload.product,
      };

    case FETCH_PRODUCT_FAILED:
      return {
        ...state,
        product: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PRODUCT_TITLE:
      return {
        ...state,
        product: {
          ...state.product,
          title: action.payload.title,
        },
      };

    case ASSIGN_PRODUCT_VENDOR:
      return {
        ...state,
        product: {
          ...state.product,
          vendor: action.payload.vendor,
        },
      };

    case ASSIGN_PRODUCT_DESCRIPTION:
      return {
        ...state,
        product: {
          ...state.product,
          description: action.payload.description,
        },
      };

    case ASSIGN_PRODUCT_COLLECTIONS:
      return {
        ...state,
        product: {
          ...state.product,
          collectionIds: _.map(action.payload.collections, 'id'),
        },
      };

    case ASSIGN_PRODUCT_PRICE:
      return {
        ...state,
        product: {
          ...state.product,
          price: action.payload.price,
        },
      };

    case ASSIGN_PRODUCT_COMPARED_AT_PRICE:
      return {
        ...state,
        product: {
          ...state.product,
          comparedAtPrice: action.payload.comparedAtPrice,
        },
      };

    case NEW_OPTION:
      return {
        ...state,
        product: {
          ...state.product,
          optionIds: [
            ...state.product.optionIds,
            action.payload.dummyId,
          ]
        }
      };

    case PULL_OPTION:
      if (isNew(action.payload.option)) {
        return {
          ...state,
          product: {
            ...state.product,
            optionIds: _.without(state.product.optionIds, action.payload.option.id),
          },
        };
      }

      return {
        ...state,
      };

    case NEW_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        product: {
          ...state.product,
          productPackageMapperIds: [
            ...state.product.productPackageMapperIds,
            action.payload.dummyId,
          ]
        }
      };

    case PULL_PRODUCT_PACKAGE_MAPPER:
      if (isNew(action.payload.productPackageMapper)) {
        return {
          ...state,
          product: {
            ...state.product,
            productPackageMapperIds: _.without(state.product.productPackageMapperIds, action.payload.productPackageMapper.id),
          },
        };
      }

      return {
        ...state,
      };


    default:
      return { ...state };
  }
};

export default productReducers;
