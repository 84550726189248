import {
  ASSIGN_ORDER_PACK_ADDITIONAL_FEE,
  ASSIGN_ORDER_PACK_BILLABLE_WEIGHT,
  ASSIGN_ORDER_PACK_DEDUCTION_AMOUNT, ASSIGN_ORDER_PACK_DISCOUNT,
  ASSIGN_ORDER_PACK_DISCOUNT_RATE,
  ASSIGN_ORDER_PACK_ESTIMATED_COST,
  ASSIGN_ORDER_PACK_EXPORT_DATE,
  ASSIGN_ORDER_PACK_GROSS_WEIGHT,
  ASSIGN_ORDER_PACK_HEIGHT, ASSIGN_ORDER_PACK_INTERNATIONAL_WAYBILL_NUMBER,
  ASSIGN_ORDER_PACK_LENGTH,
  ASSIGN_ORDER_PACK_NOTE,
  ASSIGN_ORDER_PACK_ORDER_FEE,
  ASSIGN_ORDER_PACK_QUOTATION,
  ASSIGN_ORDER_PACK_RECEIPT_ADDRESS,
  ASSIGN_ORDER_PACK_RECEIPT_CITY,
  ASSIGN_ORDER_PACK_RECEIPT_COUNTRY,
  ASSIGN_ORDER_PACK_RECEIPT_EMAIL,
  ASSIGN_ORDER_PACK_RECEIPT_MOBILE,
  ASSIGN_ORDER_PACK_RECEIPT_NAME,
  ASSIGN_ORDER_PACK_RECEIPT_POSTCODE,
  ASSIGN_ORDER_PACK_RECEIPT_STATE,
  ASSIGN_ORDER_PACK_STATUS,
  ASSIGN_ORDER_PACK_TITLE, ASSIGN_ORDER_PACK_TRACKING_NUMBER, ASSIGN_ORDER_PACK_TRANSPORT_METHOD,
  ASSIGN_ORDER_PACK_VOLUME_WEIGHT,
  ASSIGN_ORDER_PACK_WIDTH,
  FETCH_ORDER_PACK,
  FETCH_ORDER_PACK_FAILED,
  FETCH_ORDER_PACK_SUCCESS,
  INIT_ORDER_PACK
} from "redux/model/orderPacks/orderPack/constants";

const INIT_STATE = {
  orderPack: {},
  loading: true,
  error: null,
};

const orderPackReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORDER_PACK:
      return INIT_STATE;

    case FETCH_ORDER_PACK:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ORDER_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderPack: action.payload.orderPack,
      };

    case FETCH_ORDER_PACK_FAILED:
      return {
        ...state,
        orderPack: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_ORDER_PACK_RECEIPT_NAME:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptName: action.payload.receiptName,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_MOBILE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptMobile: action.payload.receiptMobile,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_EMAIL:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptEmail: action.payload.receiptEmail,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_COUNTRY:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptCountry: action.payload.receiptCountry,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_CITY:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptCity: action.payload.receiptCity,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_STATE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptState: action.payload.receiptState,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_ADDRESS:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptAddress: action.payload.receiptAddress,
        }
      };

    case ASSIGN_ORDER_PACK_RECEIPT_POSTCODE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          receiptPostcode: action.payload.receiptPostcode,
        }
      };

    case ASSIGN_ORDER_PACK_NOTE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          note: action.payload.note,
        }
      };

    case ASSIGN_ORDER_PACK_TITLE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          title: action.payload.title,
        }
      };

    case ASSIGN_ORDER_PACK_LENGTH:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          length: action.payload.length,
        }
      };

    case ASSIGN_ORDER_PACK_WIDTH:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          width: action.payload.width,
        }
      };

    case ASSIGN_ORDER_PACK_HEIGHT:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          height: action.payload.height,
        }
      };

    case ASSIGN_ORDER_PACK_GROSS_WEIGHT:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          grossWeight: action.payload.grossWeight,
        }
      };

    case ASSIGN_ORDER_PACK_STATUS:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          status: action.payload.status,
        }
      };

    case ASSIGN_ORDER_PACK_TRACKING_NUMBER:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          trackingNumber: action.payload.trackingNumber,
        }
      };

    case ASSIGN_ORDER_PACK_INTERNATIONAL_WAYBILL_NUMBER:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          internationalWaybillNumber: action.payload.internationalWaybillNumber,
        }
      };

    case ASSIGN_ORDER_PACK_VOLUME_WEIGHT:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          volumeWeight: action.payload.volumeWeight,
        }
      };

    case ASSIGN_ORDER_PACK_BILLABLE_WEIGHT:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          billableWeight: action.payload.billableWeight,
        }
      };

    case ASSIGN_ORDER_PACK_DISCOUNT_RATE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          discountRate: action.payload.discountRate,
        }
      };

    case ASSIGN_ORDER_PACK_DEDUCTION_AMOUNT:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          deductionAmount: action.payload.deductionAmount,
        }
      };

    case ASSIGN_ORDER_PACK_QUOTATION:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          quotation: action.payload.quotation,
        }
      };

    case ASSIGN_ORDER_PACK_ESTIMATED_COST:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          estimatedCost: action.payload.estimatedCost,
        }
      };

    case ASSIGN_ORDER_PACK_ORDER_FEE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          orderFee: action.payload.orderFee,
        }
      };

    case ASSIGN_ORDER_PACK_EXPORT_DATE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          exportDate: action.payload.exportDate,
        }
      };

    case ASSIGN_ORDER_PACK_DISCOUNT:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          discount: action.payload.discount,
        }
      };

    case ASSIGN_ORDER_PACK_ADDITIONAL_FEE:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          additionalFee: action.payload.additionalFee,
        }
      };

    case ASSIGN_ORDER_PACK_TRANSPORT_METHOD:
      return {
        ...state,
        orderPack: {
          ...state.orderPack,
          transportMethod: action.payload.transportMethod,
        }
      };

    default:
      return { ...state };
  }
};

export default orderPackReducers;
