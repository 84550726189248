import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_ORDER_SEA_FREIGHT,
  DELETE_ORDER_SEA_FREIGHT,
  FETCH_ORDER_SEA_FREIGHT,
  MERGE_ORDER_SEA_FREIGHTS,
  SPLIT_ORDER_SEA_FREIGHT,
  TRANSFER_ORDER_SEA_FREIGHT,
  UPDATE_ORDER_SEA_FREIGHT
} from "redux/model/orderSeaFreights/orderSeaFreight/constants";
import {
  apiCreateOrderSeaFreight,
  apiDeleteOrderSeaFreight,
  apiFetchOrderSeaFreight,
  apiMergeOrderSeaFreights,
  apiSplitOrderSeaFreight,
  apiTransferOrderSeaFreights,
  apiUpdateOrderSeaFreight
} from "api/orderSeaFreight/apiOrderSeaFreight";
import {
  createOrderSeaFreightFailed,
  createOrderSeaFreightSuccess,
  deleteOrderSeaFreightFailed,
  deleteOrderSeaFreightSuccess,
  fetchOrderSeaFreightFailed,
  fetchOrderSeaFreightSuccess,
  initOrderSeaFreight,
  transferOrderSeaFreightFailed,
  transferOrderSeaFreightSuccess,
  updateOrderSeaFreightFailed,
  updateOrderSeaFreightSuccess
} from "redux/model/orderSeaFreights/orderSeaFreight/orderSeaFreightActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal,
  startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_ORDER_SEA_FREIGHTS_PATH } from "routes/orderSeaFreight/constants";
import { fetchClientSuccess } from "redux/model/clients/client/clientActions";
import { listLineItemsSuccess } from "redux/model/lineItems/lineItems/lineItemsActions";
import { listPacksSuccess } from "redux/model/packs/packs/packsActions";
import { listLineAttachmentsSuccess } from "redux/model/lineAttachments/lineAttachments/lineAttachmentsActions";
import { fetchPaymentSuccess } from "redux/model/payments/payment/paymentActions";
import { listTransactionsSuccess } from "redux/model/transactions/transactions/transactionsActions";
import {
  pullSeaFreightContainerOrderSeaFreight
} from "redux/model/seaFreightContainers/seaFreightContainer/seaFreightContainerActions";


function* orderSeaFreightSaga() {
  yield all([
    fork(watchFetchOrderSeaFreight),
    fork(watchUpdateOrderSeaFreight),
    fork(watchCreateOrderSeaFreight),
    fork(watchDeleteOrderSeaFreight),
    fork(watchSplitOrderSeaFreight),
    fork(watchMergeOrderSeaFreights),
    fork(watchTransferOrderSeaFreights),
  ]);
}

// Fetch OrderSeaFreight
export function* watchFetchOrderSeaFreight() {
  yield takeEvery(FETCH_ORDER_SEA_FREIGHT, sagaFetchOrderSeaFreight);
}

export function* sagaFetchOrderSeaFreight({ payload }) {
  try {
    const response = yield call(apiFetchOrderSeaFreight, payload);

    const { orderSeaFreight, client, lineItems, packs, lineAttachments, payment, transactions } = response.data;

    yield put(fetchOrderSeaFreightSuccess({ orderSeaFreight }));
    yield put(fetchClientSuccess({ client }));
    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));
    yield put(listLineAttachmentsSuccess({ lineAttachments }));
    yield put(fetchPaymentSuccess({ payment }));
    yield put(listTransactionsSuccess({ transactions }));

  } catch (e) {
    yield put(fetchOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create OrderSeaFreight
export function* watchCreateOrderSeaFreight() {
  yield takeLeading(CREATE_ORDER_SEA_FREIGHT, sagaCreateOrderSeaFreight);
}

export function* sagaCreateOrderSeaFreight({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateOrderSeaFreight, payload);

    const { orderSeaFreight } = response.data;
    yield put(createOrderSeaFreightSuccess({ orderSeaFreight }));
    yield put(toastSaved({ name: `OrderSeaFreight` }));


    history.push(generateRoute(INDEX_ORDER_SEA_FREIGHTS_PATH));
    yield put(initOrderSeaFreight());


  } catch (e) {
    yield put(createOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update OrderSeaFreight
export function* watchUpdateOrderSeaFreight() {
  yield takeLeading(UPDATE_ORDER_SEA_FREIGHT, sagaUpdateOrderSeaFreight);
}

export function* sagaUpdateOrderSeaFreight({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateOrderSeaFreight, payload);

    const { orderSeaFreight } = response.data;
    yield put(updateOrderSeaFreightSuccess({ orderSeaFreight }));
    yield put(toastSaved({ name: 'OrderSeaFreight' }));
    history.push(generateRoute(INDEX_ORDER_SEA_FREIGHTS_PATH));
    yield put(initOrderSeaFreight());

  } catch (e) {
    yield put(updateOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete OrderSeaFreight
export function* watchDeleteOrderSeaFreight() {
  yield takeLeading(DELETE_ORDER_SEA_FREIGHT, sagaDeleteOrderSeaFreight);
}

export function* sagaDeleteOrderSeaFreight({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteOrderSeaFreight, payload);

    const { orderSeaFreight } = response.data;
    yield put(deleteOrderSeaFreightSuccess({ orderSeaFreight }));
    yield put(toastDeleted({ name: 'OrderSeaFreight' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Split OrderSeaFreight
export function* watchSplitOrderSeaFreight() {
  yield takeLeading(SPLIT_ORDER_SEA_FREIGHT, sagaSplitOrderSeaFreight);
}

export function* sagaSplitOrderSeaFreight({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiSplitOrderSeaFreight, payload);

    const { orderSeaFreight, lineItems, packs } = response.data;

    yield put(updateOrderSeaFreightSuccess({ orderSeaFreight }));
    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield put(toastDefault({ message: 'Order has been split' }))

  } catch (e) {
    yield put(updateOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Merge OrderSeaFreights
export function* watchMergeOrderSeaFreights() {
  yield takeLeading(MERGE_ORDER_SEA_FREIGHTS, sagaMergeOrderSeaFreights);
}

export function* sagaMergeOrderSeaFreights({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiMergeOrderSeaFreights, payload);

    const { orderSeaFreight, lineItems, packs } = response.data;

    yield put(updateOrderSeaFreightSuccess({ orderSeaFreight }));
    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield put(toastDefault({ message: 'Order has been merged' }));

  } catch (e) {
    yield put(updateOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Transfer OrderSeaFreights
export function* watchTransferOrderSeaFreights() {
  yield takeLeading(TRANSFER_ORDER_SEA_FREIGHT, sagaTransferOrderSeaFreights);
}

export function* sagaTransferOrderSeaFreights({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    yield call(apiTransferOrderSeaFreights, payload);

    const { orderSeaFreight } = payload;

    yield put(pullSeaFreightContainerOrderSeaFreight({ orderSeaFreight }));
    yield put(transferOrderSeaFreightSuccess({ orderSeaFreight }));


    yield put(toastDefault({ message: 'Order Sea Freight has been Transferred' }));

  } catch (e) {
    yield put(transferOrderSeaFreightFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

export default orderSeaFreightSaga;
