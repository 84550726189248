import _ from 'lodash';
import {
  ASSIGN_PRODUCT_PACKAGE_MAPPER_DESTROY,
  CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  DELETE_PRODUCT_PACKAGE_MAPPER_SUCCESS, NEW_PRODUCT_PACKAGE_MAPPER, PULL_PRODUCT_PACKAGE_MAPPER,
  UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS
} from "redux/model/productPackageMappers/productPackageMapper/constants";
import {
  INIT_PRODUCT_PACKAGE_MAPPERS,
  LIST_PRODUCT_PACKAGE_MAPPERS,
  LIST_PRODUCT_PACKAGE_MAPPERS_FAILED,
  LIST_PRODUCT_PACKAGE_MAPPERS_SUCCESS
} from "redux/model/productPackageMappers/productPackageMappers/constants";
import { isNew } from "@tmatt-tech/react_common";
import { NEW_PRODUCT_PACKAGE } from "redux/model/productPackages/productPackage/constants";
import { extractIdFrom } from "@tmatt-tech/react_common/dist/helpers/stringUtils";

const INIT_STATE = {
  productPackageMappers: {},
  loading: true,
  error: null,
};

const productPackageMappersReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_PACKAGE_MAPPERS:
      return INIT_STATE;

    case LIST_PRODUCT_PACKAGE_MAPPERS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PRODUCT_PACKAGE_MAPPERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productPackageMappers: _.mapKeys(action.payload.productPackageMappers, 'id'),
      };

    case LIST_PRODUCT_PACKAGE_MAPPERS_FAILED:
      return {
        ...state,
        productPackageMappers: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS:
      return {
        ...state,
        productPackageMappers: {
          [action.payload.productPackageMapper.id]: action.payload.productPackageMapper,
          ...state.productPackageMappers,
        },
      };

    case UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS:
      return {
        ...state,
        productPackageMappers: {
          ...state.productPackageMappers,
          [action.payload.productPackageMapper.id]: action.payload.productPackageMapper,
        },
      };

    case DELETE_PRODUCT_PACKAGE_MAPPER_SUCCESS:
      return {
        ...state,
        productPackageMappers: _.omit(state.productPackageMappers, action.payload.productPackageMapper.id)
      };

    case NEW_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        productPackageMappers: {
          ...state.productPackageMappers,
          [action.payload.dummyId]: {
            id: action.payload.dummyId,
            productPackageId: _.replace(action.payload.dummyId, 'productPackageMapper', 'productPackage'),
            destroy: false,
          },
        },
      };

    case PULL_PRODUCT_PACKAGE_MAPPER:
      if (isNew(action.payload.productPackageMapper)) {
        return {
          ...state,
          productPackageMappers: _.omit(state.productPackageMappers, action.payload.productPackageMapper.id),
        };
      }

      return {
        ...state,
        productPackageMappers: {
          ...state.productPackageMappers,
          [action.payload.productPackageMapper.id]: {
            ...state.productPackageMappers[action.payload.productPackageMapper.id],
            destroy: true,
          }
        }
      };

    case ASSIGN_PRODUCT_PACKAGE_MAPPER_DESTROY:
      return {
        ...state,
        productPackageMappers: {
          ...state.productPackageMappers,
          [action.payload.productPackageMapper.id]: {
            ...state.productPackageMappers[action.payload.productPackageMapper.id],
            destroy: action.payload.destroy,
          },
        },
      };

    default:
      return { ...state };
  }
};

export default productPackageMappersReducers;
