export const INIT_PRODUCT = 'INIT_PRODUCT';

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_NEW_PRODUCT = 'FETCH_NEW_PRODUCT';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const ASSIGN_PRODUCT_TITLE = 'ASSIGN_PRODUCT_TITLE';
export const ASSIGN_PRODUCT_VENDOR = 'ASSIGN_PRODUCT_VENDOR';
export const ASSIGN_PRODUCT_DESCRIPTION = 'ASSIGN_PRODUCT_DESCRIPTION';
export const ASSIGN_PRODUCT_COLLECTIONS = 'ASSIGN_PRODUCT_COLLECTIONS';
export const ASSIGN_PRODUCT_PRICE = 'ASSIGN_PRODUCT_PRICE';
export const ASSIGN_PRODUCT_COMPARED_AT_PRICE = 'ASSIGN_PRODUCT_COMPARED_AT_PRICE';