import {
  ASSIGN_PRODUCT_PACKAGE_DESTROY,
  ASSIGN_PRODUCT_PACKAGE_OPTION_ITEM_TITLES,
  ASSIGN_PRODUCT_PACKAGE_PRICE,
  ASSIGN_PRODUCT_PACKAGE_QUANTITY,
  ASSIGN_PRODUCT_PACKAGE_SKU,
  CREATE_PRODUCT_PACKAGE,
  CREATE_PRODUCT_PACKAGE_FAILED,
  CREATE_PRODUCT_PACKAGE_SUCCESS,
  DELETE_PRODUCT_PACKAGE,
  DELETE_PRODUCT_PACKAGE_FAILED,
  DELETE_PRODUCT_PACKAGE_SUCCESS,
  FETCH_PRODUCT_PACKAGE,
  FETCH_PRODUCT_PACKAGE_FAILED,
  FETCH_PRODUCT_PACKAGE_SUCCESS,
  INIT_PRODUCT_PACKAGE,
  NEW_PRODUCT_PACKAGE,
  PULL_PRODUCT_PACKAGE,
  UPDATE_PRODUCT_PACKAGE,
  UPDATE_PRODUCT_PACKAGE_FAILED,
  UPDATE_PRODUCT_PACKAGE_SUCCESS
} from "redux/model/productPackages/productPackage/constants";

export const initProductPackage = () => ({
  type: INIT_PRODUCT_PACKAGE,
  payload: {}
});

export const createProductPackage = ({ productPackage }) => ({
  type: CREATE_PRODUCT_PACKAGE,
  payload: { productPackage }
});

export const createProductPackageSuccess = ({ productPackage }) => ({
  type: CREATE_PRODUCT_PACKAGE_SUCCESS,
  payload: { productPackage }
});

export const createProductPackageFailed = ({ error }) => ({
  type: CREATE_PRODUCT_PACKAGE_FAILED,
  payload: { error }
});

export const fetchProductPackage = ({ productPackageId }) => ({
  type: FETCH_PRODUCT_PACKAGE,
  payload: { productPackageId }
});

export const fetchProductPackageSuccess = ({ productPackage }) => ({
  type: FETCH_PRODUCT_PACKAGE_SUCCESS,
  payload: { productPackage }
});

export const fetchProductPackageFailed = ({ error }) => ({
  type: FETCH_PRODUCT_PACKAGE_FAILED,
  payload: { error }
});

export const updateProductPackage = ({ productPackage }) => ({
  type: UPDATE_PRODUCT_PACKAGE,
  payload: { productPackage }
});

export const updateProductPackageSuccess = ({ productPackage }) => ({
  type: UPDATE_PRODUCT_PACKAGE_SUCCESS,
  payload: { productPackage }
});

export const updateProductPackageFailed = ({ error }) => ({
  type: UPDATE_PRODUCT_PACKAGE_FAILED,
  payload: { error }
});

export const deleteProductPackage = ({ productPackage }) => ({
  type: DELETE_PRODUCT_PACKAGE,
  payload: { productPackage }
});

export const deleteProductPackageSuccess = ({ productPackage }) => ({
  type: DELETE_PRODUCT_PACKAGE_SUCCESS,
  payload: { productPackage }
});

export const deleteProductPackageFailed = ({ error }) => ({
  type: DELETE_PRODUCT_PACKAGE_FAILED,
  payload: { error }
});

export const newProductPackage = ({ dummyId, resource }) => ({
  type: NEW_PRODUCT_PACKAGE,
  payload: { dummyId, resource }
});

export const pullProductPackage = ({ productPackage }) => ({
  type: PULL_PRODUCT_PACKAGE,
  payload: { productPackage }
});

export const assignProductPackageOptionItemTitles = ({ productPackage, optionItemTitles }) => ({
  type: ASSIGN_PRODUCT_PACKAGE_OPTION_ITEM_TITLES,
  payload: { productPackage, optionItemTitles }
});

export const assignProductPackagePrice = ({ productPackage, price }) => ({
  type: ASSIGN_PRODUCT_PACKAGE_PRICE,
  payload: { productPackage, price }
});

export const assignProductPackageQuantity = ({ productPackage, quantity }) => ({
  type: ASSIGN_PRODUCT_PACKAGE_QUANTITY,
  payload: { productPackage, quantity }
});

export const assignProductPackageSku = ({ productPackage, sku }) => ({
  type: ASSIGN_PRODUCT_PACKAGE_SKU,
  payload: { productPackage, sku }
});

export const assignProductPackageDestroy = ({ productPackage, destroy }) => ({
  type: ASSIGN_PRODUCT_PACKAGE_DESTROY,
  payload: { productPackage, destroy }
});