import { api, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_ORDER_SEA_FREIGHT_API,
  DELETE_ORDER_SEA_FREIGHT_API, DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS_API, DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS_API,
  FETCH_ORDER_SEA_FREIGHT_API,
  INDEX_ORDER_SEA_FREIGHTS_API,
  LIST_ORDER_SEA_FREIGHTS_CAM_BE_MERGED_API,
  LIST_UNCONTAINED_ORDER_SEA_FREIGHTS_API,
  MERGE_ORDER_SEA_FREIGHTS_API,
  SPLIT_ORDER_SEA_FREIGHT_API, TRANSFER_ORDER_SEA_FREIGHTS_API,
  UPDATE_ORDER_SEA_FREIGHT_API
} from "api/orderSeaFreight/constants";
import _ from "lodash";

export const apiListOrderSeaFreights = ({ page, query }) => {
  const { url, method } = INDEX_ORDER_SEA_FREIGHTS_API;

  return api({
    method,
    url,
    params: {
      page,
      query,
    }
  });
};

export const apiCreateOrderSeaFreight = ({ orderSeaFreight }) => {
  const { url, method } = CREATE_ORDER_SEA_FREIGHT_API;

  return api({
    method,
    url,
    data: orderSeaFreightParams({ orderSeaFreight }),
  });
};

export const apiFetchOrderSeaFreight = ({ orderSeaFreightId }) => {
  const { url, method } = FETCH_ORDER_SEA_FREIGHT_API;

  return api({
    method,
    url: generateRoute(url, orderSeaFreightId),
  });
};

export const apiUpdateOrderSeaFreight = ({ orderSeaFreight }) => {
  const { url, method } = UPDATE_ORDER_SEA_FREIGHT_API;

  return api({
    method,
    url: generateRoute(url, orderSeaFreight.id),
    data: orderSeaFreightParams({ orderSeaFreight }),
  });
};

export const apiDeleteOrderSeaFreight = ({ orderSeaFreight }) => {
  const { url, method } = DELETE_ORDER_SEA_FREIGHT_API;

  return api({
    method,
    url: generateRoute(url, orderSeaFreight.id),
  });
};

export const apiListUncontainedOrderSeaFreights = () => {
  const { url, method } = LIST_UNCONTAINED_ORDER_SEA_FREIGHTS_API;

  return api({
    method,
    url,
  });
};

export const apiListOrderSeaFreightsCanBeMerged = ({ client }) => {
  const { url, method } = LIST_ORDER_SEA_FREIGHTS_CAM_BE_MERGED_API;

  return api({
    method,
    url,
    data: {
      clientId: extractIdFrom(client.id),
    }
  });
};

export const apiSplitOrderSeaFreight = ({ order, lineItems }) => {
  const { url, method } = SPLIT_ORDER_SEA_FREIGHT_API;

  return api({
    method,
    url: generateRoute(url, order.id),
    data: {
      order: {
        lineItemsAttributes: _.map(lineItems, (lineItem) => {
          return { id: extractIdFrom(lineItem.id) };
        }),
      },
    },
  });
};

export const apiMergeOrderSeaFreights = ({ order, orderToBeMerged }) => {
  const { url, method } = MERGE_ORDER_SEA_FREIGHTS_API;

  return api({
    method,
    url: generateRoute(url, order.id),
    data: {
      orderToBeMerged: extractIdFrom(orderToBeMerged.id),
    },
  });
};

export const apiTransferOrderSeaFreights = ({ orderSeaFreight, seaFreightContainer }) => {
  const { url, method } = TRANSFER_ORDER_SEA_FREIGHTS_API;

  return api({
    method,
    url: generateRoute(url, orderSeaFreight.id),
    data: {
      seaFreightContainerId: extractIdFrom(seaFreightContainer.id),
    },
  });
};

export const apiDownloadProcessingOrderSeaFreights = () => {
  const { url, method, responseType } = DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

export const apiDownloadDispatchingOrderSeaFreights = () => {
  const { url, method, responseType } = DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

const orderSeaFreightParams = ({ orderSeaFreight }) => {
  const {
    receiptName,
    receiptMobile,
    receiptEmail,
    receiptCountry,
    receiptCity,
    receiptState,
    receiptAddress,
    receiptPostcode,
    note,
    title,
    length,
    width,
    height,
    grossWeight,
    status,
    logisticId,
    internationalWaybillNumber,
    volumeWeight,
    billableWeight,
    discountRate,
    deductionAmount,
    quotation,
    estimatedCost,
    orderFee,
    exportDate,
    discount,
    additionalFee,
    transportMethod,
    deliveryOrPickUp,
  } = orderSeaFreight;

  return {
    order: {
      receiptName,
      receiptMobile,
      receiptEmail,
      receiptCountry,
      receiptCity,
      receiptState,
      receiptAddress,
      receiptPostcode,
      note,
      title,
      status,
      discount,
      additionalFee,
      logisticAttributes: {
        id: extractIdFrom(logisticId),
        length,
        width,
        height,
        grossWeight,
        internationalWaybillNumber,
        volumeWeight,
        billableWeight,
        discountRate,
        deductionAmount,
        quotation,
        estimatedCost,
        orderFee,
        exportDate,
        transportMethod,
        deliveryOrPickUp,
      },
    },
  }
};
