import {
  CREATE_POINT_EXCHANGE,
  CREATE_POINT_EXCHANGE_FAILED,
  CREATE_POINT_EXCHANGE_SUCCESS,
  DELETE_POINT_EXCHANGE, DELETE_POINT_EXCHANGE_FAILED, DELETE_POINT_EXCHANGE_SUCCESS,
  FETCH_POINT_EXCHANGE,
  FETCH_POINT_EXCHANGE_FAILED,
  FETCH_POINT_EXCHANGE_SUCCESS,
  INIT_POINT_EXCHANGE,
  UPDATE_POINT_EXCHANGE,
  UPDATE_POINT_EXCHANGE_FAILED,
  UPDATE_POINT_EXCHANGE_SUCCESS
} from "redux/model/pointExchanges/pointExchange/constants";

export const initPointExchange = () => ({
  type: INIT_POINT_EXCHANGE,
  payload: {}
});

export const createPointExchange = ({ pointExchange }) => ({
  type: CREATE_POINT_EXCHANGE,
  payload: { pointExchange }
});

export const createPointExchangeSuccess = ({ pointExchange }) => ({
  type: CREATE_POINT_EXCHANGE_SUCCESS,
  payload: { pointExchange }
});

export const createPointExchangeFailed = ({ error }) => ({
  type: CREATE_POINT_EXCHANGE_FAILED,
  payload: { error }
});

export const fetchPointExchange = ({ pointExchangeId }) => ({
  type: FETCH_POINT_EXCHANGE,
  payload: { pointExchangeId }
});

export const fetchPointExchangeSuccess = ({ pointExchange }) => ({
  type: FETCH_POINT_EXCHANGE_SUCCESS,
  payload: { pointExchange }
});

export const fetchPointExchangeFailed = ({ error }) => ({
  type: FETCH_POINT_EXCHANGE_FAILED,
  payload: { error }
});

export const updatePointExchange = ({ pointExchange }) => ({
  type: UPDATE_POINT_EXCHANGE,
  payload: { pointExchange }
});

export const updatePointExchangeSuccess = ({ pointExchange }) => ({
  type: UPDATE_POINT_EXCHANGE_SUCCESS,
  payload: { pointExchange }
});

export const updatePointExchangeFailed = ({ error }) => ({
  type: UPDATE_POINT_EXCHANGE_FAILED,
  payload: { error }
});

export const deletePointExchange = ({ pointExchange }) => ({
  type: DELETE_POINT_EXCHANGE,
  payload: { pointExchange }
});

export const deletePointExchangeSuccess = ({ pointExchange }) => ({
  type: DELETE_POINT_EXCHANGE_SUCCESS,
  payload: { pointExchange }
});

export const deletePointExchangeFailed = ({ error }) => ({
  type: DELETE_POINT_EXCHANGE_FAILED,
  payload: { error }
});
