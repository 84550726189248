import {
  ASSIGN_COLLECTION_TITLE,
  CREATE_COLLECTION,
  CREATE_COLLECTION_FAILED,
  CREATE_COLLECTION_SUCCESS,
  DELETE_COLLECTION, DELETE_COLLECTION_FAILED, DELETE_COLLECTION_SUCCESS,
  FETCH_COLLECTION,
  FETCH_COLLECTION_FAILED,
  FETCH_COLLECTION_SUCCESS,
  INIT_COLLECTION,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_FAILED,
  UPDATE_COLLECTION_SUCCESS
} from "redux/model/collections/collection/constants";

export const initCollection = () => ({
  type: INIT_COLLECTION,
  payload: {}
});

export const createCollection = ({ collection }) => ({
  type: CREATE_COLLECTION,
  payload: { collection }
});

export const createCollectionSuccess = ({ collection }) => ({
  type: CREATE_COLLECTION_SUCCESS,
  payload: { collection }
});

export const createCollectionFailed = ({ error }) => ({
  type: CREATE_COLLECTION_FAILED,
  payload: { error }
});

export const fetchCollection = ({ collectionId }) => ({
  type: FETCH_COLLECTION,
  payload: { collectionId }
});

export const fetchCollectionSuccess = ({ collection }) => ({
  type: FETCH_COLLECTION_SUCCESS,
  payload: { collection }
});

export const fetchCollectionFailed = ({ error }) => ({
  type: FETCH_COLLECTION_FAILED,
  payload: { error }
});

export const updateCollection = ({ collection }) => ({
  type: UPDATE_COLLECTION,
  payload: { collection }
});

export const updateCollectionSuccess = ({ collection }) => ({
  type: UPDATE_COLLECTION_SUCCESS,
  payload: { collection }
});

export const updateCollectionFailed = ({ error }) => ({
  type: UPDATE_COLLECTION_FAILED,
  payload: { error }
});

export const deleteCollection = ({ collection }) => ({
  type: DELETE_COLLECTION,
  payload: { collection }
});

export const deleteCollectionSuccess = ({ collection }) => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload: { collection }
});

export const deleteCollectionFailed = ({ error }) => ({
  type: DELETE_COLLECTION_FAILED,
  payload: { error }
});

export const assignCollectionTitle = ({ title }) => ({
  type: ASSIGN_COLLECTION_TITLE,
  payload: { title }
});
