import {
  ASSIGN_WECHAT_NOTICE_ACTIVE,
  ASSIGN_WECHAT_NOTICE_DESCRIPTION,
  ASSIGN_WECHAT_NOTICE_TITLE,
  FETCH_WECHAT_NOTICE,
  FETCH_WECHAT_NOTICE_FAILED,
  FETCH_WECHAT_NOTICE_SUCCESS,
  INIT_WECHAT_NOTICE
} from "redux/model/wechatNotices/wechatNotice/constants";

const INIT_STATE = {
  wechatNotice: {
    active: true,
  },
  loading: true,
  error: null,
};

const wechatNoticeReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_WECHAT_NOTICE:
      return INIT_STATE;

    case FETCH_WECHAT_NOTICE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_WECHAT_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wechatNotice: action.payload.wechatNotice,
      };

    case FETCH_WECHAT_NOTICE_FAILED:
      return {
        ...state,
        wechatNotice: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_WECHAT_NOTICE_TITLE:
      return {
        ...state,
        wechatNotice: {
          ...state.wechatNotice,
          title: action.payload.title,
        },
      };

    case ASSIGN_WECHAT_NOTICE_DESCRIPTION:
      return {
        ...state,
        wechatNotice: {
          ...state.wechatNotice,
          description: action.payload.description,
        },
      };

    case ASSIGN_WECHAT_NOTICE_ACTIVE:
      return {
        ...state,
        wechatNotice: {
          ...state.wechatNotice,
          active: action.payload.active,
        },
      };

    default:
      return { ...state };
  }
};

export default wechatNoticeReducers;
