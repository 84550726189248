
export const CREATE_COLLECTION_API = {
  url: `/eshop/collections`,
  method: 'post'
};

export const INDEX_COLLECTIONS_API = {
  url: `/eshop/collections`,
  method: 'get',
};

export const FETCH_COLLECTION_API = {
  url: `/eshop/collections/:id`,
  method: 'get',
};

export const UPDATE_COLLECTION_API = {
  url: `/eshop/collections/:id`,
  method: 'put'
};

export const DELETE_COLLECTION_API = {
  url: `/eshop/collections/:id`,
  method: 'delete'
};
