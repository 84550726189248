import {all} from 'redux-saga/effects';
import packBatchUploadSaga from "redux/model/packBatchUploads/packBatchUpload/packBatchUploadSaga";
import orderPacksSaga from "redux/model/orderPacks/orderPacks/orderPacksSaga";
import clientSaga from "./clients/client/clientSaga";
import clientsSaga from "./clients/clients/clientsSaga";
import packSaga from "./packs/pack/packSaga";
import packsSaga from "./packs/packs/packsSaga";
import orderPackSaga from "redux/model/orderPacks/orderPack/orderPackSaga";
import lineItemSaga from "redux/model/lineItems/lineItem/lineItemSaga";
import lineItemsSaga from "redux/model/lineItems/lineItems/lineItemsSaga";
import exportDimensionBatchUploadSaga
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/exportDimensionBatchUploadSaga";
import exportDimensionBatchUploadsSaga
  from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUploads/exportDimensionBatchUploadsSaga";
import exportInternationalBatchUploadSaga
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/exportInternationalBatchUploadSaga";
import exportInternationalBatchUploadsSaga
  from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/exportInternationalBatchUploadsSaga";
import lineAttachmentSaga from "redux/model/lineAttachments/lineAttachment/lineAttachmentSaga";
import lineAttachmentsSaga from "redux/model/lineAttachments/lineAttachments/lineAttachmentsSaga";
import transactionSaga from "redux/model/transactions/transaction/transactionSaga";
import transactionsSaga from "redux/model/transactions/transactions/transactionsSaga";
import receivedNoticeSaga from "redux/model/receivedNotices/receivedNotice/receivedNoticeSaga";
import receivedNoticesSaga from "redux/model/receivedNotices/receivedNotices/receivedNoticesSaga";
import paymentSaga from "redux/model/payments/payment/paymentSaga";
import paymentsSaga from "redux/model/payments/payments/paymentsSaga";
import couponRuleSaga from "redux/model/couponRules/couponRule/couponRuleSaga";
import couponRulesSaga from "redux/model/couponRules/couponRules/couponRulesSaga";
import wechatNoticeSaga from "redux/model/wechatNotices/wechatNotice/wechatNoticeSaga";
import wechatNoticesSaga from "redux/model/wechatNotices/wechatNotices/wechatNoticesSaga";
import receiptAddressSaga from "redux/model/receiptAddresses/receiptAddress/receiptAddressSaga";
import receiptAddressesSaga from "redux/model/receiptAddresses/receiptAddresses/receiptAddressesSaga";
import productSaga from "redux/model/products/product/productSaga";
import productsSaga from "redux/model/products/products/productsSaga";
import collectionSaga from "redux/model/collections/collection/collectionSaga";
import collectionsSaga from "redux/model/collections/collections/collectionsSaga";
import orderSeaFreightSaga from "redux/model/orderSeaFreights/orderSeaFreight/orderSeaFreightSaga";
import orderSeaFreightsSaga from "redux/model/orderSeaFreights/orderSeaFreights/orderSeaFreightsSaga";
import seaFreightContainerSaga from "redux/model/seaFreightContainers/seaFreightContainer/seaFreightContainerSaga";
import seaFreightContainersSaga from "redux/model/seaFreightContainers/seaFreightContainers/seaFreightContainersSaga";
import orderDeclarationSaga from "redux/model/orderDeclarations/orderDeclaration/orderDeclarationSaga";
import orderDeclarationsSaga from "redux/model/orderDeclarations/orderDeclarations/orderDeclarationsSaga";
import lineItemDeclarationSaga from "redux/model/lineItemDeclarations/lineItemDeclaration/lineItemDeclarationSaga";
import lineItemDeclarationsSaga from "redux/model/lineItemDeclarations/lineItemDeclarations/lineItemDeclarationsSaga";
import seaFreightContainerDeclarationSaga
  from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/seaFreightContainerDeclarationSaga";
import seaFreightContainerDeclarationsSaga
  from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclarations/seaFreightContainerDeclarationsSaga";
import productPackageMapperSaga from "redux/model/productPackageMappers/productPackageMapper/productPackageMapperSaga";
import productPackageMappersSaga
  from "redux/model/productPackageMappers/productPackageMappers/productPackageMappersSaga";
import productPackageSaga from "redux/model/productPackages/productPackage/productPackageSaga";
import productPackagesSaga from "redux/model/productPackages/productPackages/productPackagesSaga";
import optionsSaga from "redux/model/options/options/optionsSaga";
import optionItemsSaga from "redux/model/optionItems/optionItems/optionItemsSaga";
import seaFreightConsigneeSaga from "redux/model/seaFreightConsignees/seaFreightConsignee/seaFreightConsigneeSaga";
import seaFreightConsigneesSaga from "redux/model/seaFreightConsignees/seaFreightConsignees/seaFreightConsigneesSaga";
import seaFreightExporterSaga from "redux/model/seaFreightExporters/seaFreightExporter/seaFreightExporterSaga";
import seaFreightExportersSaga from "redux/model/seaFreightExporters/seaFreightExporters/seaFreightExportersSaga";
import pointExchangeSaga from "redux/model/pointExchanges/pointExchange/pointExchangeSaga";
import pointExchangesSaga from "redux/model/pointExchanges/pointExchanges/pointExchangesSaga";
import currencyExchangeSaga from "redux/model/currencyExchanges/currencyExchange/currencyExchangeSaga";
import currencyExchangesSaga from "redux/model/currencyExchanges/currencyExchanges/currencyExchangesSaga";


function* modelSaga() {
  yield all([
    packBatchUploadSaga(),
    orderPackSaga(),
    orderPacksSaga(),
    clientSaga(),
    clientsSaga(),
    packSaga(),
    packsSaga(),
    lineItemSaga(),
    lineItemsSaga(),
    exportDimensionBatchUploadSaga(),
    exportDimensionBatchUploadsSaga(),
    exportInternationalBatchUploadSaga(),
    exportInternationalBatchUploadsSaga(),
    lineAttachmentSaga(),
    lineAttachmentsSaga(),
    transactionSaga(),
    transactionsSaga(),
    receivedNoticeSaga(),
    receivedNoticesSaga(),
    paymentSaga(),
    paymentsSaga(),
    couponRuleSaga(),
    couponRulesSaga(),
    wechatNoticeSaga(),
    wechatNoticesSaga(),
    receiptAddressSaga(),
    receiptAddressesSaga(),
    productSaga(),
    productsSaga(),
    collectionSaga(),
    collectionsSaga(),
    orderSeaFreightSaga(),
    orderSeaFreightsSaga(),
    seaFreightContainerSaga(),
    seaFreightContainersSaga(),
    orderDeclarationSaga(),
    orderDeclarationsSaga(),
    lineItemDeclarationSaga(),
    lineItemDeclarationsSaga(),
    seaFreightContainerDeclarationSaga(),
    seaFreightContainerDeclarationsSaga(),
    productPackageMapperSaga(),
    productPackageMappersSaga(),
    productPackageSaga(),
    productPackagesSaga(),
    optionsSaga(),
    optionItemsSaga(),
    seaFreightConsigneeSaga(),
    seaFreightConsigneesSaga(),
    seaFreightExporterSaga(),
    seaFreightExportersSaga(),
    pointExchangeSaga(),
    pointExchangesSaga(),
    currencyExchangeSaga(),
    currencyExchangesSaga(),
  ]);
}

export default modelSaga;
