export const INIT_POINT_EXCHANGE = 'INIT_POINT_EXCHANGE';

export const FETCH_POINT_EXCHANGE = 'FETCH_POINT_EXCHANGE';
export const FETCH_POINT_EXCHANGE_SUCCESS = 'FETCH_POINT_EXCHANGE_SUCCESS';
export const FETCH_POINT_EXCHANGE_FAILED = 'FETCH_POINT_EXCHANGE_FAILED';

export const CREATE_POINT_EXCHANGE = 'CREATE_POINT_EXCHANGE';
export const CREATE_POINT_EXCHANGE_SUCCESS = 'CREATE_POINT_EXCHANGE_SUCCESS';
export const CREATE_POINT_EXCHANGE_FAILED = 'CREATE_POINT_EXCHANGE_FAILED';

export const UPDATE_POINT_EXCHANGE = 'UPDATE_POINT_EXCHANGE';
export const UPDATE_POINT_EXCHANGE_SUCCESS = 'UPDATE_POINT_EXCHANGE_SUCCESS';
export const UPDATE_POINT_EXCHANGE_FAILED = 'UPDATE_POINT_EXCHANGE_FAILED';

export const DELETE_POINT_EXCHANGE = 'DELETE_POINT_EXCHANGE';
export const DELETE_POINT_EXCHANGE_SUCCESS = 'DELETE_POINT_EXCHANGE_SUCCESS';
export const DELETE_POINT_EXCHANGE_FAILED = 'DELETE_POINT_EXCHANGE_FAILED';
