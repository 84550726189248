import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_PRODUCT_PACKAGE_API,
  INDEX_PRODUCT_PACKAGES_API,
  FETCH_PRODUCT_PACKAGE_API,
  DELETE_PRODUCT_PACKAGE_API,
  UPDATE_PRODUCT_PACKAGE_API
} from "api/productPackage/constants";

export const apiListProductPackages = () => {
  const { url, method } = INDEX_PRODUCT_PACKAGES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateProductPackage = ({ productPackage }) => {
  const { url, method } = CREATE_PRODUCT_PACKAGE_API;

  return api({
    method,
    url,
    data: productPackageParams({ productPackage }),
  });
};

export const apiFetchProductPackage = ({ productPackageId }) => {
  const { url, method } = FETCH_PRODUCT_PACKAGE_API;

  return api({
    method,
    url: generateRoute(url, productPackageId),
  });
};

export const apiUpdateProductPackage = ({ productPackage }) => {
  const { url, method } = UPDATE_PRODUCT_PACKAGE_API;

  return api({
    method,
    url: generateRoute(url, productPackage.id),
    data: productPackageParams({ productPackage }),
  });
};

export const apiDeleteProductPackage = ({ productPackage }) => {
  const { url, method } = DELETE_PRODUCT_PACKAGE_API;

  return api({
    method,
    url: generateRoute(url, productPackage.id),
  });
};

const productPackageParams = ({ productPackage }) => {
  const {} = productPackage;

  return {
  }
};
