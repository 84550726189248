import {
  ASSIGN_PRODUCT_PACKAGE_MAPPER_DESTROY,
  CREATE_PRODUCT_PACKAGE_MAPPER,
  CREATE_PRODUCT_PACKAGE_MAPPER_FAILED,
  CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  DELETE_PRODUCT_PACKAGE_MAPPER, DELETE_PRODUCT_PACKAGE_MAPPER_FAILED, DELETE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  FETCH_PRODUCT_PACKAGE_MAPPER,
  FETCH_PRODUCT_PACKAGE_MAPPER_FAILED,
  FETCH_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  INIT_PRODUCT_PACKAGE_MAPPER, NEW_PRODUCT_PACKAGE_MAPPER, PULL_PRODUCT_PACKAGE_MAPPER,
  UPDATE_PRODUCT_PACKAGE_MAPPER,
  UPDATE_PRODUCT_PACKAGE_MAPPER_FAILED,
  UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS
} from "redux/model/productPackageMappers/productPackageMapper/constants";

export const initProductPackageMapper = () => ({
  type: INIT_PRODUCT_PACKAGE_MAPPER,
  payload: {}
});

export const createProductPackageMapper = ({ productPackageMapper }) => ({
  type: CREATE_PRODUCT_PACKAGE_MAPPER,
  payload: { productPackageMapper }
});

export const createProductPackageMapperSuccess = ({ productPackageMapper }) => ({
  type: CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  payload: { productPackageMapper }
});

export const createProductPackageMapperFailed = ({ error }) => ({
  type: CREATE_PRODUCT_PACKAGE_MAPPER_FAILED,
  payload: { error }
});

export const fetchProductPackageMapper = ({ productPackageMapperId }) => ({
  type: FETCH_PRODUCT_PACKAGE_MAPPER,
  payload: { productPackageMapperId }
});

export const fetchProductPackageMapperSuccess = ({ productPackageMapper }) => ({
  type: FETCH_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  payload: { productPackageMapper }
});

export const fetchProductPackageMapperFailed = ({ error }) => ({
  type: FETCH_PRODUCT_PACKAGE_MAPPER_FAILED,
  payload: { error }
});

export const updateProductPackageMapper = ({ productPackageMapper }) => ({
  type: UPDATE_PRODUCT_PACKAGE_MAPPER,
  payload: { productPackageMapper }
});

export const updateProductPackageMapperSuccess = ({ productPackageMapper }) => ({
  type: UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  payload: { productPackageMapper }
});

export const updateProductPackageMapperFailed = ({ error }) => ({
  type: UPDATE_PRODUCT_PACKAGE_MAPPER_FAILED,
  payload: { error }
});

export const deleteProductPackageMapper = ({ productPackageMapper }) => ({
  type: DELETE_PRODUCT_PACKAGE_MAPPER,
  payload: { productPackageMapper }
});

export const deleteProductPackageMapperSuccess = ({ productPackageMapper }) => ({
  type: DELETE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  payload: { productPackageMapper }
});

export const deleteProductPackageMapperFailed = ({ error }) => ({
  type: DELETE_PRODUCT_PACKAGE_MAPPER_FAILED,
  payload: { error }
});

export const newProductPackageMapper = ({ dummyId, optionItems }) => ({
  type: NEW_PRODUCT_PACKAGE_MAPPER,
  payload: { dummyId, optionItems }
});

export const pullProductPackageMapper = ({ productPackageMapper }) => ({
  type: PULL_PRODUCT_PACKAGE_MAPPER,
  payload: { productPackageMapper }
});

export const assignProductPackageMapperDestroy = ({ productPackageMapper, destroy }) => ({
  type: ASSIGN_PRODUCT_PACKAGE_MAPPER_DESTROY,
  payload: { productPackageMapper, destroy }
});