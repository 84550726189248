import _ from 'lodash';
import {
  ASSIGN_OPTION_TITLE,
  INIT_OPTIONS,
  LIST_OPTIONS,
  LIST_OPTIONS_FAILED,
  LIST_OPTIONS_SUCCESS,
  NEW_OPTION,
  PULL_OPTION
} from "redux/model/options/options/constants";
import { isNew } from "@tmatt-tech/react_common";
import { NEW_OPTION_ITEM, PULL_OPTION_ITEM } from "redux/model/optionItems/optionItems/constants";

const INIT_STATE = {
  options: {},
  loading: true,
  error: null,
};

const optionsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_OPTIONS:
      return INIT_STATE;

    case LIST_OPTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        options: _.mapKeys(action.payload.options, 'id'),
      };

    case LIST_OPTIONS_FAILED:
      return {
        ...state,
        options: {},
        loading: false,
        error: action.payload.error,
      };

    case NEW_OPTION:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.dummyId]: {
            id: action.payload.dummyId,
            title: '',
            destroy: false,
            optionItemIds: [],
          }
        }
      };

    case PULL_OPTION:
      if (isNew(action.payload.option)) {
        return {
          ...state,
          options: _.omit(state.options, action.payload.option.id),
        };
      }

      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.option.id]: {
            ...state.options[action.payload.option.id],
            destroy: true,
          },
        },
      };

    case ASSIGN_OPTION_TITLE:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.option.id]: {
            ...state.options[action.payload.option.id],
            title: action.payload.title,
          },
        },
      };

    case NEW_OPTION_ITEM:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.resource.id]: {
            ...state.options[action.payload.resource.id],
            optionItemIds: [
              ...state.options[action.payload.resource.id].optionItemIds,
              action.payload.dummyId,
            ],
          },
        }
      };

    case PULL_OPTION_ITEM:
      if (isNew(action.payload.optionItem)) {
        return {
          ...state,
          options: {
            ...state.options,
            [action.payload.resource.id]: {
              ...state.options[action.payload.resource.id],
              optionItemIds: _.without(
                state.options[action.payload.resource.id].optionItemIds,
                action.payload.optionItem.id
              ),
            },
          }
        };
      }

      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export default optionsReducers;
