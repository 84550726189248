import {
  ASSIGN_OPTION_TITLE,
  INIT_OPTIONS,
  LIST_OPTIONS,
  LIST_OPTIONS_FAILED,
  LIST_OPTIONS_SUCCESS, NEW_OPTION, PULL_OPTION, PULL_OPTION_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS
} from "redux/model/options/options/constants";

export const initOptions = () => ({
  type: INIT_OPTIONS,
  payload: {}
});

export const listOptions = () => ({
  type: LIST_OPTIONS,
  payload: {}
});

export const listOptionsSuccess = ({ options }) => ({
  type: LIST_OPTIONS_SUCCESS,
  payload: { options }
});

export const listOptionsFailed = ({ error }) => ({
  type: LIST_OPTIONS_FAILED,
  payload: { error }
});

export const newOption = ({ dummyId, resource }) => ({
  type: NEW_OPTION,
  payload: { dummyId, resource }
});

export const pullOptionSettleDownProductPackageMappers = ({
                                                            option,
                                                            options,
                                                            optionItems,
                                                            productPackageMappers,
                                                            productPackages,
                                                          }) => ({
  type: PULL_OPTION_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
  payload: {
    option,
    options,
    optionItems,
    productPackageMappers,
    productPackages,
  }
});

export const pullOption = ({ option }) => ({
  type: PULL_OPTION,
  payload: { option }
});

export const assignOptionTitle = ({ option, title }) => ({
  type: ASSIGN_OPTION_TITLE,
  payload: { option, title }
});
