import {
  ERROR_FORM_PRODUCT_PACKAGE,
  LOADING_FORM_PRODUCT_PACKAGE,
  RESET_FORM_PRODUCT_PACKAGE
} from "redux/ui/form/productPackage/constants";
import {
  CREATE_PRODUCT_PACKAGE,
  CREATE_PRODUCT_PACKAGE_FAILED,
  CREATE_PRODUCT_PACKAGE_SUCCESS,
  UPDATE_PRODUCT_PACKAGE, UPDATE_PRODUCT_PACKAGE_FAILED, UPDATE_PRODUCT_PACKAGE_SUCCESS
} from "redux/model/productPackages/productPackage/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formProductPackageReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PRODUCT_PACKAGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PRODUCT_PACKAGE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PRODUCT_PACKAGE:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PRODUCT_PACKAGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PRODUCT_PACKAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PRODUCT_PACKAGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PRODUCT_PACKAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formProductPackageReducers;
