import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  FETCH_COLLECTION,
  UPDATE_COLLECTION
} from "redux/model/collections/collection/constants";
import {
  apiCreateCollection,
  apiDeleteCollection,
  apiFetchCollection,
  apiUpdateCollection
} from "api/collection/apiCollection";
import {
  createCollectionFailed,
  createCollectionSuccess,
  deleteCollectionFailed,
  deleteCollectionSuccess,
  fetchCollectionFailed,
  fetchCollectionSuccess,
  initCollection,
  updateCollectionFailed,
  updateCollectionSuccess
} from "redux/model/collections/collection/collectionActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_COLLECTIONS_PATH } from "routes/collection/constants";


function* collectionSaga() {
  yield all([
    fork(watchFetchCollection),
    fork(watchUpdateCollection),
    fork(watchCreateCollection),
    fork(watchDeleteCollection),
  ]);
}

// Fetch Collection
export function* watchFetchCollection() {
  yield takeEvery(FETCH_COLLECTION, sagaFetchCollection);
}

export function* sagaFetchCollection({ payload }) {
  try {
    const response = yield call(apiFetchCollection, payload);

    const { collection } = response.data;
    yield put(fetchCollectionSuccess({ collection }));

  } catch (e) {
    yield put(fetchCollectionFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Collection
export function* watchCreateCollection() {
  yield takeLeading(CREATE_COLLECTION, sagaCreateCollection);
}

export function* sagaCreateCollection({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateCollection, payload);

    const { collection } = response.data;
    yield put(createCollectionSuccess({ collection }));
    yield put(toastSaved({ name: `Collection` }));


    history.push(generateRoute(INDEX_COLLECTIONS_PATH));
    yield put(initCollection());


  } catch (e) {
    yield put(createCollectionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Collection
export function* watchUpdateCollection() {
  yield takeLeading(UPDATE_COLLECTION, sagaUpdateCollection);
}

export function* sagaUpdateCollection({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateCollection, payload);

    const { collection } = response.data;
    yield put(updateCollectionSuccess({ collection }));
    yield put(toastSaved({ name: 'Collection' }));
    history.push(generateRoute(INDEX_COLLECTIONS_PATH));
    yield put(initCollection());

  } catch (e) {
    yield put(updateCollectionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Collection
export function* watchDeleteCollection() {
  yield takeLeading(DELETE_COLLECTION, sagaDeleteCollection);
}

export function* sagaDeleteCollection({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteCollection, payload);

    const { collection } = response.data;
    yield put(deleteCollectionSuccess({ collection }));
    yield put(toastDeleted({ name: 'Collection' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteCollectionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default collectionSaga;
