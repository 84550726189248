import { combineReducers } from 'redux';
import formPackBatchUploadReducer from "redux/ui/form/packBatchUpload/formPackBatchUploadReducers";
import formClientReducers from "./client/formClientReducers";
import formPackReducers from "./pack/formPackReducers";
import formLineItemReducers from "redux/ui/form/lineItem/formLineItemReducers";
import formOrderPackReducers from "redux/ui/form/orderPack/formOrderPackReducers";
import formWarehouseReducers from "redux/ui/form/warehouse/formWarehouseReducers";
import formExportDimensionBatchUploadReducers
  from "redux/ui/form/exportDimensionBatchUpload/formExportDimensionBatchUploadReducers";
import formExportInternationalBatchUploadReducers
  from "redux/ui/form/exportInternationalBatchUpload/formExportInternationalBatchUploadReducers";
import formLineAttachmentReducers from "redux/ui/form/lineAttachment/formLineAttachmentReducers";
import formTransactionReducers from "redux/ui/form/transaction/formTransactionReducers";
import formReceivedNoticeReducers from "redux/ui/form/receivedNotice/formReceivedNoticeReducers";
import formPaymentReducers from "redux/ui/form/payment/formPaymentReducers";
import formCouponRuleReducers from "redux/ui/form/couponRule/formCouponRuleReducers";
import formBatchRedeemCouponRuleReducers from "redux/ui/form/batchRedeemCouponRule/formBatchRedeemCouponRuleReducers";
import formWechatNoticeReducers from "redux/ui/form/wechatNotice/formWechatNoticeReducers";
import formReceiptAddressReducers from "redux/ui/form/receiptAddress/formReceiptAddressReducers";
import formProductReducers from "redux/ui/form/product/formProductReducers";
import formCollectionReducers from "redux/ui/form/collection/formCollectionReducers";
import formOrderSeaFreightReducers from "redux/ui/form/orderSeaFreight/formOrderSeaFreightReducers";
import formSeaFreightContainerReducers from "redux/ui/form/seaFreightContainer/formSeaFreightContainerReducers";
import formOrderDeclarationReducers from "redux/ui/form/orderDeclaration/formOrderDeclarationReducers";
import formLineItemDeclarationReducers from "redux/ui/form/lineItemDeclaration/formLineItemDeclarationReducers";
import formProductPackageMapperReducers from "redux/ui/form/productPackageMapper/formProductPackageMapperReducers";
import formProductPackageReducers from "redux/ui/form/productPackage/formProductPackageReducers";
import formSeaFreightConsigneeReducers from "redux/ui/form/seaFreightConsignee/formSeaFreightConsigneeReducers";
import formSeaFreightExporterReducers from "redux/ui/form/seaFreightExporter/formSeaFreightExporterReducers";
import formPointExchangeReducers from "redux/ui/form/pointExchange/formPointExchangeReducers";

export default combineReducers({
  packBatchUpload: formPackBatchUploadReducer,
  client: formClientReducers,
  pack: formPackReducers,
  lineItem: formLineItemReducers,
  orderPack: formOrderPackReducers,
  warehouse: formWarehouseReducers,
  exportDimensionBatchUpload: formExportDimensionBatchUploadReducers,
  exportInternationalBatchUpload: formExportInternationalBatchUploadReducers,
  lineAttachment: formLineAttachmentReducers,
  transaction: formTransactionReducers,
  receivedNotice: formReceivedNoticeReducers,
  payment: formPaymentReducers,
  couponRule: formCouponRuleReducers,
  batchRedeemCouponRule: formBatchRedeemCouponRuleReducers,
  wechatNotice: formWechatNoticeReducers,
  receiptAddress: formReceiptAddressReducers,
  collection: formCollectionReducers,
  product: formProductReducers,
  orderSeaFreight: formOrderSeaFreightReducers,
  seaFreightContainer: formSeaFreightContainerReducers,
  orderDeclaration: formOrderDeclarationReducers,
  lineItemDeclaration: formLineItemDeclarationReducers,
  productPackageMapper: formProductPackageMapperReducers,
  productPackage: formProductPackageReducers,
  seaFreightConsignee: formSeaFreightConsigneeReducers,
  seaFreightExporter: formSeaFreightExporterReducers,
  pointExchange: formPointExchangeReducers,
});
