import {
  INIT_PRODUCT_PACKAGE_MAPPERS,
  LIST_PRODUCT_PACKAGE_MAPPERS,
  LIST_PRODUCT_PACKAGE_MAPPERS_FAILED,
  LIST_PRODUCT_PACKAGE_MAPPERS_SUCCESS, SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS
} from "redux/model/productPackageMappers/productPackageMappers/constants";

export const initProductPackageMappers = () => ({
  type: INIT_PRODUCT_PACKAGE_MAPPERS,
  payload: {}
});

export const listProductPackageMappers = () => ({
  type: LIST_PRODUCT_PACKAGE_MAPPERS,
  payload: {}
});

export const listProductPackageMappersSuccess = ({ productPackageMappers }) => ({
  type: LIST_PRODUCT_PACKAGE_MAPPERS_SUCCESS,
  payload: { productPackageMappers }
});

export const listProductPackageMappersFailed = ({ error }) => ({
  type: LIST_PRODUCT_PACKAGE_MAPPERS_FAILED,
  payload: { error }
});

export const settleDownProductPackageMappers = ({
                                              optionItem,
                                              title,
                                              options,
                                              optionItems,
                                              productPackageMappers,
                                              productPackages,
                                            }) => ({
  type: SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS,
  payload: {
    optionItem,
    title,
    options,
    optionItems,
    productPackageMappers,
    productPackages,
  }
});