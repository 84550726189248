import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  settleDownProductPackageMappers
} from "redux/model/productPackageMappers/productPackageMappers/productPackageMappersActions";
import { throwFrontError } from "@tmatt-tech/react_common";
import { PULL_OPTION_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS } from "redux/model/options/options/constants";
import { pullOption } from "redux/model/options/options/optionsActions";
import _ from 'lodash';

function* optionsSaga() {
  yield all([
    fork(watchPullOptionSettleDownProductPackageMappers),
  ]);
}

// Pull Option Settle Down Product Package Mappers
export function* watchPullOptionSettleDownProductPackageMappers() {
  yield takeEvery(PULL_OPTION_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS, sagaPullOptionSettleDownProductPackageMappers);
}

export function* sagaPullOptionSettleDownProductPackageMappers({ payload }) {
  try {
    const {
      option,
      options,
      optionItems,
      productPackageMappers,
      productPackages,
    } = payload;

    yield put(pullOption({ option }));
    yield put(settleDownProductPackageMappers({
      options: _.filter(options, (item) => {
        return item.id !== option.id;
      }),
      optionItems,
      productPackageMappers,
      productPackages,
    }));

  } catch (e) {
    throwFrontError(e);
  }
}


export default optionsSaga;
