import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_COLLECTION_API,
  INDEX_COLLECTIONS_API,
  FETCH_COLLECTION_API,
  DELETE_COLLECTION_API,
  UPDATE_COLLECTION_API
} from "api/collection/constants";

export const apiListCollections = () => {
  const { url, method } = INDEX_COLLECTIONS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateCollection = ({ collection }) => {
  const { url, method } = CREATE_COLLECTION_API;

  return api({
    method,
    url,
    data: collectionParams({ collection }),
  });
};

export const apiFetchCollection = ({ collectionId }) => {
  const { url, method } = FETCH_COLLECTION_API;

  return api({
    method,
    url: generateRoute(url, collectionId),
  });
};

export const apiUpdateCollection = ({ collection }) => {
  const { url, method } = UPDATE_COLLECTION_API;

  return api({
    method,
    url: generateRoute(url, collection.id),
    data: collectionParams({ collection }),
  });
};

export const apiDeleteCollection = ({ collection }) => {
  const { url, method } = DELETE_COLLECTION_API;

  return api({
    method,
    url: generateRoute(url, collection.id),
  });
};

const collectionParams = ({ collection }) => {
  const { title } = collection;

  return {
    eshopCollection: {
      title
    }
  }
};
