import _ from 'lodash';
import {
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_SUCCESS
} from "redux/model/products/product/constants";
import {
  INIT_PRODUCTS,
  LIST_PRODUCTS,
  LIST_PRODUCTS_FAILED,
  LIST_PRODUCTS_SUCCESS
} from "redux/model/products/products/constants";

const INIT_STATE = {
  products: {},
  loading: true,
  error: null,
};

const productsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCTS:
      return INIT_STATE;

    case LIST_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: _.mapKeys(action.payload.products, 'id'),
      };

    case LIST_PRODUCTS_FAILED:
      return {
        ...state,
        products: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: {
          [action.payload.product.id]: action.payload.product,
          ...state.products,
        },
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.product.id]: action.payload.product,
        },
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: _.omit(state.products, action.payload.product.id)
      };

    default:
      return { ...state };
  }
};

export default productsReducers;
