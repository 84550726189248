import {
  ASSIGN_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  ASSIGN_SEA_FREIGHT_CONTAINER_STATUS,
  CREATE_SEA_FREIGHT_CONTAINER,
  CREATE_SEA_FREIGHT_CONTAINER_FAILED,
  CREATE_SEA_FREIGHT_CONTAINER_SUCCESS,
  DELETE_SEA_FREIGHT_CONTAINER,
  DELETE_SEA_FREIGHT_CONTAINER_FAILED,
  DELETE_SEA_FREIGHT_CONTAINER_SUCCESS,
  FETCH_SEA_FREIGHT_CONTAINER,
  FETCH_SEA_FREIGHT_CONTAINER_FAILED,
  FETCH_SEA_FREIGHT_CONTAINER_SUCCESS,
  INIT_SEA_FREIGHT_CONTAINER,
  NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  PULL_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  UPDATE_SEA_FREIGHT_CONTAINER,
  UPDATE_SEA_FREIGHT_CONTAINER_FAILED,
  UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS
} from "redux/model/seaFreightContainers/seaFreightContainer/constants";

export const initSeaFreightContainer = () => ({
  type: INIT_SEA_FREIGHT_CONTAINER,
  payload: {}
});

export const createSeaFreightContainer = ({ seaFreightContainer }) => ({
  type: CREATE_SEA_FREIGHT_CONTAINER,
  payload: { seaFreightContainer }
});

export const createSeaFreightContainerSuccess = ({ seaFreightContainer }) => ({
  type: CREATE_SEA_FREIGHT_CONTAINER_SUCCESS,
  payload: { seaFreightContainer }
});

export const createSeaFreightContainerFailed = ({ error }) => ({
  type: CREATE_SEA_FREIGHT_CONTAINER_FAILED,
  payload: { error }
});

export const fetchSeaFreightContainer = ({ seaFreightContainerId }) => ({
  type: FETCH_SEA_FREIGHT_CONTAINER,
  payload: { seaFreightContainerId }
});

export const fetchSeaFreightContainerSuccess = ({ seaFreightContainer }) => ({
  type: FETCH_SEA_FREIGHT_CONTAINER_SUCCESS,
  payload: { seaFreightContainer }
});

export const fetchSeaFreightContainerFailed = ({ error }) => ({
  type: FETCH_SEA_FREIGHT_CONTAINER_FAILED,
  payload: { error }
});

export const updateSeaFreightContainer = ({ seaFreightContainer }) => ({
  type: UPDATE_SEA_FREIGHT_CONTAINER,
  payload: { seaFreightContainer }
});

export const updateSeaFreightContainerSuccess = ({ seaFreightContainer }) => ({
  type: UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS,
  payload: { seaFreightContainer }
});

export const updateSeaFreightContainerFailed = ({ error }) => ({
  type: UPDATE_SEA_FREIGHT_CONTAINER_FAILED,
  payload: { error }
});

export const deleteSeaFreightContainer = ({ seaFreightContainer }) => ({
  type: DELETE_SEA_FREIGHT_CONTAINER,
  payload: { seaFreightContainer }
});

export const deleteSeaFreightContainerSuccess = ({ seaFreightContainer }) => ({
  type: DELETE_SEA_FREIGHT_CONTAINER_SUCCESS,
  payload: { seaFreightContainer }
});

export const deleteSeaFreightContainerFailed = ({ error }) => ({
  type: DELETE_SEA_FREIGHT_CONTAINER_FAILED,
  payload: { error }
});

export const newSeaFreightContainerOrderSeaFreight = ({ dummyId }) => ({
  type: NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  payload: { dummyId }
});

export const assignSeaFreightContainerOrderSeaFreight = ({ previousOrderSeaFreight, newOrderSeaFreight }) => ({
  type: ASSIGN_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  payload: { previousOrderSeaFreight, newOrderSeaFreight }
});

export const pullSeaFreightContainerOrderSeaFreight = ({ orderSeaFreight }) => ({
  type: PULL_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreight }
});

export const assignSeaFreightContainerStatus = ({ status }) => ({
  type: ASSIGN_SEA_FREIGHT_CONTAINER_STATUS,
  payload: { status }
});