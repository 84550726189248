import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_PRODUCT_PACKAGE,
  DELETE_PRODUCT_PACKAGE,
  FETCH_PRODUCT_PACKAGE,
  UPDATE_PRODUCT_PACKAGE
} from "redux/model/productPackages/productPackage/constants";
import {
  apiCreateProductPackage,
  apiDeleteProductPackage,
  apiFetchProductPackage,
  apiUpdateProductPackage
} from "api/productPackage/apiProductPackage";
import {
  createProductPackageFailed,
  createProductPackageSuccess,
  deleteProductPackageFailed,
  deleteProductPackageSuccess,
  fetchProductPackageFailed,
  fetchProductPackageSuccess,
  initProductPackage,
  updateProductPackageFailed,
  updateProductPackageSuccess
} from "redux/model/productPackages/productPackage/productPackageActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_PRODUCT_PACKAGES_PATH } from "routes/productPackage/constants";


function* productPackageSaga() {
  yield all([
    fork(watchFetchProductPackage),
    fork(watchUpdateProductPackage),
    fork(watchCreateProductPackage),
    fork(watchDeleteProductPackage),
  ]);
}

// Fetch ProductPackage
export function* watchFetchProductPackage() {
  yield takeEvery(FETCH_PRODUCT_PACKAGE, sagaFetchProductPackage);
}

export function* sagaFetchProductPackage({ payload }) {
  try {
    const response = yield call(apiFetchProductPackage, payload);

    const { productPackage } = response.data;
    yield put(fetchProductPackageSuccess({ productPackage }));

  } catch (e) {
    yield put(fetchProductPackageFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ProductPackage
export function* watchCreateProductPackage() {
  yield takeLeading(CREATE_PRODUCT_PACKAGE, sagaCreateProductPackage);
}

export function* sagaCreateProductPackage({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateProductPackage, payload);

    const { productPackage } = response.data;
    yield put(createProductPackageSuccess({ productPackage }));
    yield put(toastSaved({ name: `ProductPackage` }));


    history.push(generateRoute(INDEX_PRODUCT_PACKAGES_PATH));
    yield put(initProductPackage());


  } catch (e) {
    yield put(createProductPackageFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ProductPackage
export function* watchUpdateProductPackage() {
  yield takeLeading(UPDATE_PRODUCT_PACKAGE, sagaUpdateProductPackage);
}

export function* sagaUpdateProductPackage({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateProductPackage, payload);

    const { productPackage } = response.data;
    yield put(updateProductPackageSuccess({ productPackage }));
    yield put(toastSaved({ name: 'ProductPackage' }));
    history.push(generateRoute(INDEX_PRODUCT_PACKAGES_PATH));
    yield put(initProductPackage());

  } catch (e) {
    yield put(updateProductPackageFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ProductPackage
export function* watchDeleteProductPackage() {
  yield takeLeading(DELETE_PRODUCT_PACKAGE, sagaDeleteProductPackage);
}

export function* sagaDeleteProductPackage({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteProductPackage, payload);

    const { productPackage } = response.data;
    yield put(deleteProductPackageSuccess({ productPackage }));
    yield put(toastDeleted({ name: 'ProductPackage' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteProductPackageFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default productPackageSaga;
