import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { INDEX_POINT_EXCHANGES_PATH, SHOW_POINT_EXCHANGE_PATH } from "routes/pointExchange/constants";

const PointExchanges = React.lazy(() => import('pages/pointExchanges/index/PointExchanges'));
const NewPointExchange = React.lazy(() => import('pages/pointExchanges/new/NewPointExchange'));
const ShowPointExchange = React.lazy(() => import('pages/pointExchanges/show/ShowPointExchange'));

export const pointExchangeRoutes = {
  path: INDEX_POINT_EXCHANGES_PATH,
  exact: true,
  name: 'PointExchanges',
  icon: 'far fa-star',
  component: PointExchanges,
  route: PrivateRoute,
};

export const hiddenPointExchangeRoutes = {
  children: [
    {
      path: SHOW_POINT_EXCHANGE_PATH,
      exact: true,
      name: 'Show PointExchange',
      component: ShowPointExchange,
      route: PrivateRoute,
    }
  ]
}
