export const INIT_ATTACHMENT_LIST = 'INIT_ATTACHMENT_LIST';

export const FETCH_ATTACHMENT_LIST = 'FETCH_ATTACHMENT_LIST';
export const FETCH_ATTACHMENT_LIST_SUCCESS = 'FETCH_ATTACHMENT_LIST_SUCCESS';
export const FETCH_ATTACHMENT_LIST_FAILED = 'FETCH_ATTACHMENT_LIST_FAILED';

export const CREATE_ATTACHMENT_LIST = 'CREATE_ATTACHMENT_LIST';
export const CREATE_ATTACHMENT_LIST_SUCCESS = 'CREATE_ATTACHMENT_LIST_SUCCESS';
export const CREATE_ATTACHMENT_LIST_FAILED = 'CREATE_ATTACHMENT_LIST_FAILED';

export const UPDATE_ATTACHMENT_LIST = 'UPDATE_ATTACHMENT_LIST';
export const UPDATE_ATTACHMENT_LIST_SUCCESS = 'UPDATE_ATTACHMENT_LIST_SUCCESS';
export const UPDATE_ATTACHMENT_LIST_FAILED = 'UPDATE_ATTACHMENT_LIST_FAILED';

export const DELETE_ATTACHMENT_LIST = 'DELETE_ATTACHMENT_LIST';
export const DELETE_ATTACHMENT_LIST_SUCCESS = 'DELETE_ATTACHMENT_LIST_SUCCESS';
export const DELETE_ATTACHMENT_LIST_FAILED = 'DELETE_ATTACHMENT_LIST_FAILED';
