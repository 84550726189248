import { api, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_ORDER_PACK_API,
  DELETE_ORDER_PACK_API,
  DOWNLOAD_DISPATCHING_ORDERS_CSV_API,
  DOWNLOAD_PROCESSING_ORDERS_CSV_API,
  FETCH_ORDER_PACK_API,
  INDEX_ORDER_PACKS_API,
  LIST_ORDER_PACKS_CAM_BE_MERGED_API,
  MERGE_ORDER_PACKS_API,
  SPLIT_ORDER_PACK_API,
  UPDATE_ORDER_PACK_API
} from "api/orderPack/constants";
import _ from "lodash";

export const apiListOrderPacks = ({ page, query }) => {
  const { url, method } = INDEX_ORDER_PACKS_API;

  return api({
    method,
    url,
    params: {
      page,
      query,
    }
  });
};

export const apiListOrderPacksCanBeMerged = ({ client }) => {
  const { url, method } = LIST_ORDER_PACKS_CAM_BE_MERGED_API;

  return api({
    method,
    url,
    data: {
      clientId: extractIdFrom(client.id),
    }
  });
};

export const apiCreateOrderPack = ({ orderPack }) => {
  const { url, method } = CREATE_ORDER_PACK_API;

  return api({
    method,
    url,
    data: orderPackParams(orderPack),
  });
};

export const apiFetchOrderPack = ({ orderPackId }) => {
  const { url, method } = FETCH_ORDER_PACK_API;

  return api({
    method,
    url: generateRoute(url, orderPackId),
  });
};

export const apiUpdateOrderPack = ({ orderPack }) => {
  const { url, method } = UPDATE_ORDER_PACK_API;

  return api({
    method,
    url: generateRoute(url, orderPack.id),
    data: orderPackParams(orderPack),
  });
};

export const apiDeleteOrderPack = ({ orderPack }) => {
  const { url, method } = DELETE_ORDER_PACK_API;

  return api({
    method,
    url: generateRoute(url, orderPack.id),
  });
};

export const apiSplitOrderPack = ({ order, lineItems }) => {
  const { url, method } = SPLIT_ORDER_PACK_API;

  return api({
    method,
    url: generateRoute(url, order.id),
    data: {
      order: {
        lineItemsAttributes: _.map(lineItems, (lineItem) => {
          return { id: extractIdFrom(lineItem.id) };
        }),
      },
    },
  });
};

export const apiMergeOrderPacks = ({ order, orderToBeMerged }) => {
  const { url, method } = MERGE_ORDER_PACKS_API;

  return api({
    method,
    url: generateRoute(url, order.id),
    data: {
      orderToBeMerged: extractIdFrom(orderToBeMerged.id),
    },
  });
};

export const apiDownloadProcessingOrdersCsv = () => {
  const { url, method, responseType } = DOWNLOAD_PROCESSING_ORDERS_CSV_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

export const apiDownloadDispatchingOrdersCsv = () => {
  const { url, method, responseType } = DOWNLOAD_DISPATCHING_ORDERS_CSV_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};


const orderPackParams = (orderPack) => {
  const {
    receiptName,
    receiptMobile,
    receiptEmail,
    receiptCountry,
    receiptCity,
    receiptState,
    receiptAddress,
    receiptPostcode,
    note,
    title,
    length,
    width,
    height,
    grossWeight,
    status,
    logisticId,
    internationalWaybillNumber,
    volumeWeight,
    billableWeight,
    discountRate,
    deductionAmount,
    quotation,
    estimatedCost,
    orderFee,
    exportDate,
    discount,
    additionalFee,
    transportMethod,
  } = orderPack;

  return {
    order: {
      receiptName,
      receiptMobile,
      receiptEmail,
      receiptCountry,
      receiptCity,
      receiptState,
      receiptAddress,
      receiptPostcode,
      note,
      title,
      status,
      discount,
      additionalFee,
      logisticAttributes: {
        id: extractIdFrom(logisticId),
        length,
        width,
        height,
        grossWeight,
        internationalWaybillNumber,
        volumeWeight,
        billableWeight,
        discountRate,
        deductionAmount,
        quotation,
        estimatedCost,
        orderFee,
        exportDate,
        transportMethod,
      },
    },
  };
};
