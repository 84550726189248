import {
  ERROR_FORM_POINT_EXCHANGE,
  LOADING_FORM_POINT_EXCHANGE,
  RESET_FORM_POINT_EXCHANGE
} from "redux/ui/form/pointExchange/constants";
import {
  CREATE_POINT_EXCHANGE,
  CREATE_POINT_EXCHANGE_FAILED,
  CREATE_POINT_EXCHANGE_SUCCESS,
  UPDATE_POINT_EXCHANGE, UPDATE_POINT_EXCHANGE_FAILED, UPDATE_POINT_EXCHANGE_SUCCESS
} from "redux/model/pointExchanges/pointExchange/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPointExchangeReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_POINT_EXCHANGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_POINT_EXCHANGE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_POINT_EXCHANGE:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_POINT_EXCHANGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_POINT_EXCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_POINT_EXCHANGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_POINT_EXCHANGE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_POINT_EXCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_POINT_EXCHANGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formPointExchangeReducers;
