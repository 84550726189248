import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_PRODUCT_PACKAGE_MAPPER,
  DELETE_PRODUCT_PACKAGE_MAPPER,
  FETCH_PRODUCT_PACKAGE_MAPPER,
  UPDATE_PRODUCT_PACKAGE_MAPPER
} from "redux/model/productPackageMappers/productPackageMapper/constants";
import {
  apiCreateProductPackageMapper,
  apiDeleteProductPackageMapper,
  apiFetchProductPackageMapper,
  apiUpdateProductPackageMapper
} from "api/productPackageMapper/apiProductPackageMapper";
import {
  createProductPackageMapperFailed,
  createProductPackageMapperSuccess,
  deleteProductPackageMapperFailed,
  deleteProductPackageMapperSuccess,
  fetchProductPackageMapperFailed,
  fetchProductPackageMapperSuccess,
  initProductPackageMapper,
  updateProductPackageMapperFailed,
  updateProductPackageMapperSuccess
} from "redux/model/productPackageMappers/productPackageMapper/productPackageMapperActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_PRODUCT_PACKAGE_MAPPERS_PATH } from "routes/productPackageMapper/constants";


function* productPackageMapperSaga() {
  yield all([
    fork(watchFetchProductPackageMapper),
    fork(watchUpdateProductPackageMapper),
    fork(watchCreateProductPackageMapper),
    fork(watchDeleteProductPackageMapper),
  ]);
}

// Fetch ProductPackageMapper
export function* watchFetchProductPackageMapper() {
  yield takeEvery(FETCH_PRODUCT_PACKAGE_MAPPER, sagaFetchProductPackageMapper);
}

export function* sagaFetchProductPackageMapper({ payload }) {
  try {
    const response = yield call(apiFetchProductPackageMapper, payload);

    const { productPackageMapper } = response.data;
    yield put(fetchProductPackageMapperSuccess({ productPackageMapper }));

  } catch (e) {
    yield put(fetchProductPackageMapperFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ProductPackageMapper
export function* watchCreateProductPackageMapper() {
  yield takeLeading(CREATE_PRODUCT_PACKAGE_MAPPER, sagaCreateProductPackageMapper);
}

export function* sagaCreateProductPackageMapper({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateProductPackageMapper, payload);

    const { productPackageMapper } = response.data;
    yield put(createProductPackageMapperSuccess({ productPackageMapper }));
    yield put(toastSaved({ name: `ProductPackageMapper` }));


    history.push(generateRoute(INDEX_PRODUCT_PACKAGE_MAPPERS_PATH));
    yield put(initProductPackageMapper());


  } catch (e) {
    yield put(createProductPackageMapperFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ProductPackageMapper
export function* watchUpdateProductPackageMapper() {
  yield takeLeading(UPDATE_PRODUCT_PACKAGE_MAPPER, sagaUpdateProductPackageMapper);
}

export function* sagaUpdateProductPackageMapper({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateProductPackageMapper, payload);

    const { productPackageMapper } = response.data;
    yield put(updateProductPackageMapperSuccess({ productPackageMapper }));
    yield put(toastSaved({ name: 'ProductPackageMapper' }));
    history.push(generateRoute(INDEX_PRODUCT_PACKAGE_MAPPERS_PATH));
    yield put(initProductPackageMapper());

  } catch (e) {
    yield put(updateProductPackageMapperFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ProductPackageMapper
export function* watchDeleteProductPackageMapper() {
  yield takeLeading(DELETE_PRODUCT_PACKAGE_MAPPER, sagaDeleteProductPackageMapper);
}

export function* sagaDeleteProductPackageMapper({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteProductPackageMapper, payload);

    const { productPackageMapper } = response.data;
    yield put(deleteProductPackageMapperSuccess({ productPackageMapper }));
    yield put(toastDeleted({ name: 'ProductPackageMapper' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteProductPackageMapperFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default productPackageMapperSaga;
