import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_NEW_PRODUCT,
  FETCH_PRODUCT,
  UPDATE_PRODUCT
} from "redux/model/products/product/constants";
import {
  apiCreateProductNormal,
  apiDeleteProductNormal,
  apiFetchNewProductNormal,
  apiFetchProductNormal,
  apiUpdateProductNormal
} from "api/product/apiProduct";
import {
  createProductFailed,
  createProductSuccess,
  deleteProductFailed,
  deleteProductSuccess,
  fetchProductFailed,
  fetchProductSuccess,
  initProduct,
  updateProductFailed,
  updateProductSuccess
} from "redux/model/products/product/productActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_PRODUCTS_PATH } from "routes/product/constants";
import { listCollectionsSuccess } from "redux/model/collections/collections/collectionsActions";
import { listOptionsSuccess } from "redux/model/options/options/optionsActions";
import { listOptionItemsSuccess } from "redux/model/optionItems/optionItems/optionItemsActions";
import { listAttachmentListsSuccess } from "redux/model/attachmentLists/attachmentLists/attachmentListsActions";
import { listLineAttachmentsSuccess } from "redux/model/lineAttachments/lineAttachments/lineAttachmentsActions";
import {
  listProductPackageMappersSuccess
} from "redux/model/productPackageMappers/productPackageMappers/productPackageMappersActions";
import { listProductPackagesSuccess } from "redux/model/productPackages/productPackages/productPackagesActions";


function* productSaga() {
  yield all([
    fork(watchFetchProduct),
    fork(watchFetchNewProduct),
    fork(watchUpdateProduct),
    fork(watchCreateProduct),
    fork(watchDeleteProduct),
  ]);
}

// Fetch Product
export function* watchFetchProduct() {
  yield takeEvery(FETCH_PRODUCT, sagaFetchProduct);
}

export function* sagaFetchProduct({ payload }) {
  try {
    const response = yield call(apiFetchProductNormal, payload);

    const {
      product,
      collections,
      options,
      optionItems,
      attachmentLists,
      lineAttachments,
      productPackageMappers,
      productPackages,
    } = response.data;
    yield put(fetchProductSuccess({ product }));
    yield put(listCollectionsSuccess({ collections }));
    yield put(listOptionsSuccess({ options }));
    yield put(listOptionItemsSuccess({ optionItems }));
    yield put(listLineAttachmentsSuccess({ lineAttachments }));
    yield put(listAttachmentListsSuccess({ attachmentLists }));
    yield put(listProductPackageMappersSuccess({ productPackageMappers }));
    yield put(listProductPackagesSuccess({ productPackages }));

  } catch (e) {
    yield put(fetchProductFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Product
export function* watchFetchNewProduct() {
  yield takeEvery(FETCH_NEW_PRODUCT, sagaFetchNewProduct);
}

export function* sagaFetchNewProduct({ payload }) {
  try {
    const response = yield call(apiFetchNewProductNormal);

    const {
      product,
      collections,
      options,
      optionItems,
      attachmentLists,
      lineAttachments,
      productPackageMappers,
      productPackages,
    } = response.data;

    yield put(fetchProductSuccess({ product }));
    yield put(listCollectionsSuccess({ collections }));
    yield put(listOptionsSuccess({ options }));
    yield put(listOptionItemsSuccess({ optionItems }));
    yield put(listLineAttachmentsSuccess({ lineAttachments }));
    yield put(listAttachmentListsSuccess({ attachmentLists }));
    yield put(listProductPackageMappersSuccess({ productPackageMappers }));
    yield put(listProductPackagesSuccess({ productPackages }));

  } catch (e) {
    yield put(fetchProductFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Product
export function* watchCreateProduct() {
  yield takeLeading(CREATE_PRODUCT, sagaCreateProduct);
}

export function* sagaCreateProduct({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateProductNormal, payload);

    const { product } = response.data;
    yield put(createProductSuccess({ product }));
    yield put(toastSaved({ name: `Product` }));


    history.push(generateRoute(INDEX_PRODUCTS_PATH));
    yield put(initProduct());


  } catch (e) {
    yield put(createProductFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Product
export function* watchUpdateProduct() {
  yield takeLeading(UPDATE_PRODUCT, sagaUpdateProduct);
}

export function* sagaUpdateProduct({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateProductNormal, payload);

    const { product } = response.data;
    yield put(updateProductSuccess({ product }));
    yield put(toastSaved({ name: 'Product' }));
    history.push(generateRoute(INDEX_PRODUCTS_PATH));
    yield put(initProduct());

  } catch (e) {
    yield put(updateProductFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Product
export function* watchDeleteProduct() {
  yield takeLeading(DELETE_PRODUCT, sagaDeleteProduct);
}

export function* sagaDeleteProduct({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteProductNormal, payload);

    const { product } = response.data;
    yield put(deleteProductSuccess({ product }));
    yield put(toastDeleted({ name: 'Product' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteProductFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default productSaga;
