import {
  ERROR_FORM_PRODUCT,
  LOADING_FORM_PRODUCT,
  RESET_FORM_PRODUCT
} from "redux/ui/form/product/constants";
import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT, UPDATE_PRODUCT_FAILED, UPDATE_PRODUCT_SUCCESS
} from "redux/model/products/product/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formProductReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PRODUCT:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PRODUCT:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formProductReducers;
