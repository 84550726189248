import {
  ERROR_FORM_PRODUCT_PACKAGE_MAPPER,
  LOADING_FORM_PRODUCT_PACKAGE_MAPPER,
  RESET_FORM_PRODUCT_PACKAGE_MAPPER
} from "redux/ui/form/productPackageMapper/constants";
import {
  CREATE_PRODUCT_PACKAGE_MAPPER,
  CREATE_PRODUCT_PACKAGE_MAPPER_FAILED,
  CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  UPDATE_PRODUCT_PACKAGE_MAPPER, UPDATE_PRODUCT_PACKAGE_MAPPER_FAILED, UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS
} from "redux/model/productPackageMappers/productPackageMapper/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formProductPackageMapperReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PRODUCT_PACKAGE_MAPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PRODUCT_PACKAGE_MAPPER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PRODUCT_PACKAGE_MAPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PRODUCT_PACKAGE_MAPPER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formProductPackageMapperReducers;
