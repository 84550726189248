
export const CREATE_PRODUCT_NORMAL_API = {
  url: `/eshop/product/normals`,
  method: 'post'
};

export const INDEX_PRODUCT_NORMALS_API = {
  url: `/eshop/product/normals`,
  method: 'get',
};

export const FETCH_PRODUCT_NORMAL_API = {
  url: `/eshop/product/normals/:id`,
  method: 'get',
};

export const FETCH_NEW_PRODUCT_NORMAL_API = {
  url: `/eshop/product/normals/fetch_new`,
  method: 'post',
};

export const UPDATE_PRODUCT_NORMAL_API = {
  url: `/eshop/product/normals/:id`,
  method: 'put'
};

export const DELETE_PRODUCT_NORMAL_API = {
  url: `/eshop/product/normals/:id`,
  method: 'delete'
};
