import {
  ERROR_FORM_COLLECTION,
  LOADING_FORM_COLLECTION,
  RESET_FORM_COLLECTION
} from "redux/ui/form/collection/constants";
import {
  CREATE_COLLECTION,
  CREATE_COLLECTION_FAILED,
  CREATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION, UPDATE_COLLECTION_FAILED, UPDATE_COLLECTION_SUCCESS
} from "redux/model/collections/collection/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formCollectionReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_COLLECTION:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_COLLECTION:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formCollectionReducers;
