import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import {
  BASE_ORDER_SEA_FREIGHT_PATH,
  INDEX_ORDER_SEA_FREIGHTS_PATH,
  SHOW_ORDER_SEA_FREIGHT_PATH
} from "routes/orderSeaFreight/constants";

const OrderSeaFreights = React.lazy(() => import('pages/orderSeaFreights/index/OrderSeaFreights'));
const ShowOrderSeaFreight = React.lazy(() => import('pages/orderSeaFreights/show/ShowOrderSeaFreight'));

export const orderSeaFreightRoutes = {
  path: BASE_ORDER_SEA_FREIGHT_PATH,
  name: '拼柜',
  icon: 'far fa-ship',
  header: "客服",
  children: [
    {
      path: INDEX_ORDER_SEA_FREIGHTS_PATH,
      exact: true,
      name: '拼柜订单',
      component: OrderSeaFreights,
      route: PrivateRoute,
    },
  ],
};

export const hiddenOrderSeaFreightRoutes = {
  children: [
    {
      path: SHOW_ORDER_SEA_FREIGHT_PATH,
      exact: true,
      name: '拼柜订单',
      component: ShowOrderSeaFreight,
      route: PrivateRoute,
    }
  ]
}
