import { api, extractIdFrom, generateRoute, isNew } from "@tmatt-tech/react_common";
import {
  CREATE_PRODUCT_NORMAL_API, DELETE_PRODUCT_NORMAL_API, FETCH_NEW_PRODUCT_NORMAL_API,
  FETCH_PRODUCT_NORMAL_API,
  INDEX_PRODUCT_NORMALS_API,
  UPDATE_PRODUCT_NORMAL_API
} from "api/product/constants";
import _ from 'lodash';
import { isNewId } from "@tmatt-tech/react_common/dist/helpers/objectUtils";

export const apiListProductNormals = () => {
  const { url, method } = INDEX_PRODUCT_NORMALS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateProductNormal = ({ product }) => {
  const { url, method } = CREATE_PRODUCT_NORMAL_API;

  return api({
    method,
    url,
    data: productNormalParams({ product }),
  });
};

export const apiFetchProductNormal = ({ productId }) => {
  const { url, method } = FETCH_PRODUCT_NORMAL_API;

  return api({
    method,
    url: generateRoute(url, productId),
  });
};

export const apiFetchNewProductNormal = () => {
  const { url, method } = FETCH_NEW_PRODUCT_NORMAL_API;

  return api({
    method,
    url,
  });
};

export const apiUpdateProductNormal = ({ product }) => {
  const { url, method } = UPDATE_PRODUCT_NORMAL_API;

  return api({
    method,
    url: generateRoute(url, product.id),
    data: productNormalParams({ product }),
  });
};

export const apiDeleteProductNormal = ({ product }) => {
  const { url, method } = DELETE_PRODUCT_NORMAL_API;

  return api({
    method,
    url: generateRoute(url, product.id),
  });
};

const productNormalParams = ({ product }) => {
  const {
    title,
    vendor,
    description,
    price,
    comparedAtPrice,
    options,
    collectionIds,
    collectionProductMappers,
    attachmentListCarousel,
    attachmentListContent,
    productPackageMappers,
  } = product;

  const eshopOptionsAttributes = _.map(options, (option) => {
    const { title, destroy, optionItems } = option;

    const eshopOptionItemsAttributes = _.map(optionItems, (optionItem) => {
      return {
        id: isNew(optionItem) ? null : extractIdFrom(optionItem.id),
        title: optionItem.title,
        _destroy: optionItem.destroy,
      }
    })

    return {
      id: isNew(option) ? null : extractIdFrom(option.id),
      title,
      eshopOptionItemsAttributes,
      _destroy: destroy,
    }
  });

  const adjustedCollectionProductMappers = _.map(collectionProductMappers, (mapper) => {
    return {
      ...mapper,
      id: extractIdFrom(mapper.id),
      collectionId: extractIdFrom(mapper.collectionId),
      _destroy: !_.includes(collectionIds, mapper.collectionId)
    }
  });

  const previousCollectionIds = _.map(collectionProductMappers, 'collectionId');

  const newCollectionProductMappers = _.chain(collectionIds)
    .filter(collectionId => !_.includes(previousCollectionIds, collectionId))
    .map((collectionId) => {
      return {
        id: null,
        collectionId: extractIdFrom(collectionId),
        _destroy: false,
      };
    })
    .value()

  const eshopCollectionProductMappersAttributes = [
    ...adjustedCollectionProductMappers,
    ...newCollectionProductMappers,
  ];

  const generalAttachmentListCarouselAttributes = {
    id: isNew(attachmentListCarousel) ? null : extractIdFrom(attachmentListCarousel.id),
    lineAttachmentsAttributes: _.map(attachmentListCarousel.lineAttachmentIds, (lineAttachmentId, index) => {
      return {
        id: extractIdFrom(lineAttachmentId),
        position: index + 1,
      }
    }),
  }

  const generalAttachmentListContentAttributes = {
    id: isNew(attachmentListContent) ? null : extractIdFrom(attachmentListContent.id),
    lineAttachmentsAttributes: _.map(attachmentListContent.lineAttachmentIds, (lineAttachmentId, index) => {
      return {
        id: extractIdFrom(lineAttachmentId),
        position: index + 1,
      }
    }),
  };

  const eshopProductPackageMappersAttributes = _.map(productPackageMappers, (productPackageMapper) => {
    const { id: productPackageMapperId, destroy, productPackage } = productPackageMapper;
    const { id: productPackageId, price, quantity, sku, optionItemTitles } = productPackage;

    return {
      id: isNewId(productPackageMapperId) ? null : extractIdFrom(productPackageMapperId),
      _destroy: destroy,
      eshopProductPackageAttributes: {
        id: isNewId(productPackageId) ? null : extractIdFrom(productPackageId),
        price,
        quantity,
        sku,
        eshopOptionItemTitles: optionItemTitles,
      },
    };
  });

  return {
    eshopProductNormal: {
      title,
      vendor,
      description,
      price,
      comparedAtPrice,
      eshopCollectionProductMappersAttributes,
      eshopOptionsAttributes,
      generalAttachmentListCarouselAttributes,
      generalAttachmentListContentAttributes,
      eshopProductPackageMappersAttributes,
    },
  }
};
