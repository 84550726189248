import {
  ASSIGN_PRODUCT_COLLECTIONS, ASSIGN_PRODUCT_COMPARED_AT_PRICE,
  ASSIGN_PRODUCT_DESCRIPTION, ASSIGN_PRODUCT_PRICE,
  ASSIGN_PRODUCT_TITLE,
  ASSIGN_PRODUCT_VENDOR,
  CREATE_PRODUCT,
  CREATE_PRODUCT_FAILED,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FAILED,
  DELETE_PRODUCT_SUCCESS, FETCH_NEW_PRODUCT,
  FETCH_PRODUCT,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_SUCCESS,
  INIT_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_SUCCESS
} from "redux/model/products/product/constants";

export const initProduct = () => ({
  type: INIT_PRODUCT,
  payload: {}
});

export const createProduct = ({ product }) => ({
  type: CREATE_PRODUCT,
  payload: { product }
});

export const createProductSuccess = ({ product }) => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload: { product }
});

export const createProductFailed = ({ error }) => ({
  type: CREATE_PRODUCT_FAILED,
  payload: { error }
});

export const fetchNewProduct = () => ({
  type: FETCH_NEW_PRODUCT,
  payload: {}
});

export const fetchProduct = ({ productId }) => ({
  type: FETCH_PRODUCT,
  payload: { productId }
});

export const fetchProductSuccess = ({ product }) => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: { product }
});

export const fetchProductFailed = ({ error }) => ({
  type: FETCH_PRODUCT_FAILED,
  payload: { error }
});

export const updateProduct = ({ product }) => ({
  type: UPDATE_PRODUCT,
  payload: { product }
});

export const updateProductSuccess = ({ product }) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: { product }
});

export const updateProductFailed = ({ error }) => ({
  type: UPDATE_PRODUCT_FAILED,
  payload: { error }
});

export const deleteProduct = ({ product }) => ({
  type: DELETE_PRODUCT,
  payload: { product }
});

export const deleteProductSuccess = ({ product }) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: { product }
});

export const deleteProductFailed = ({ error }) => ({
  type: DELETE_PRODUCT_FAILED,
  payload: { error }
});

export const assignProductTitle = ({ title }) => ({
  type: ASSIGN_PRODUCT_TITLE,
  payload: { title }
});

export const assignProductVendor = ({ vendor }) => ({
  type: ASSIGN_PRODUCT_VENDOR,
  payload: { vendor }
});

export const assignProductDescription = ({ description }) => ({
  type: ASSIGN_PRODUCT_DESCRIPTION,
  payload: { description }
});

export const assignProductCollections = ({ collections }) => ({
  type: ASSIGN_PRODUCT_COLLECTIONS,
  payload: { collections }
});

export const assignProductPrice = ({ price }) => ({
  type: ASSIGN_PRODUCT_PRICE,
  payload: { price }
});

export const assignProductComparedAtPrice = ({ comparedAtPrice }) => ({
  type: ASSIGN_PRODUCT_COMPARED_AT_PRICE,
  payload: { comparedAtPrice }
});