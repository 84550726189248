import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_COLLECTION_PATH, INDEX_COLLECTIONS_PATH, NEW_COLLECTION_PATH, SHOW_COLLECTION_PATH } from "routes/collection/constants";

const Collections = React.lazy(() => import('pages/collections/index/Collections'));
const NewCollection = React.lazy(() => import('pages/collections/new/NewCollection'));
const ShowCollection = React.lazy(() => import('pages/collections/show/ShowCollection'));

export const collectionRoutes = {
  path: BASE_COLLECTION_PATH,
  header: "电商",
  name: '产品分类',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_COLLECTIONS_PATH,
      exact: true,
      name: '类别',
      component: Collections,
      route: PrivateRoute,
    },
    {
      path: NEW_COLLECTION_PATH,
      exact: true,
      name: '添加新分类',
      component: NewCollection,
      route: PrivateRoute,
    },
  ],
};

export const hiddenCollectionRoutes = {
  children: [
    {
      path: SHOW_COLLECTION_PATH,
      exact: true,
      name: '显示类别',
      component: ShowCollection,
      route: PrivateRoute,
    }
  ]
}
