import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_EXPORT_DIMENSION_BATCH_UPLOAD_PATH, INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_PATH, NEW_EXPORT_DIMENSION_BATCH_UPLOAD_PATH, SHOW_EXPORT_DIMENSION_BATCH_UPLOAD_PATH } from "routes/exportDimensionBatchUpload/constants";

const ExportDimensionBatchUploads = React.lazy(() => import('pages/exportDimensionBatchUploads/index/ExportDimensionBatchUploads'));
const NewExportDimensionBatchUpload = React.lazy(() => import('pages/exportDimensionBatchUploads/new/NewExportDimensionBatchUpload'));
const ShowExportDimensionBatchUpload = React.lazy(() => import('pages/exportDimensionBatchUploads/show/ShowExportDimensionBatchUpload'));

export const exportDimensionBatchUploadRoutes = {
  path: BASE_EXPORT_DIMENSION_BATCH_UPLOAD_PATH,
  name: '出库重量体积表上传',
  icon: 'far fa-star',
  component: NewExportDimensionBatchUpload,
  route: PrivateRoute,
  // children: [
  //   {
  //     path: INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_PATH,
  //     exact: true,
  //     name: 'ExportDimensionBatchUploads',
  //     component: ExportDimensionBatchUploads,
  //     route: PrivateRoute,
  //   },
  //   {
  //     path: NEW_EXPORT_DIMENSION_BATCH_UPLOAD_PATH,
  //     exact: true,
  //     name: 'Add New',
  //     component: NewExportDimensionBatchUpload,
  //     route: PrivateRoute,
  //   },
  // ],
};

export const hiddenExportDimensionBatchUploadRoutes = {
  children: [
    // {
    //   path: SHOW_EXPORT_DIMENSION_BATCH_UPLOAD_PATH,
    //   exact: true,
    //   name: 'Show ExportDimensionBatchUpload',
    //   component: ShowExportDimensionBatchUpload,
    //   route: PrivateRoute,
    // }
  ]
}
