import _ from 'lodash';
import {
  ASSIGN_PRODUCT_PACKAGE_DESTROY,
  ASSIGN_PRODUCT_PACKAGE_OPTION_ITEM_TITLES,
  ASSIGN_PRODUCT_PACKAGE_PRICE,
  ASSIGN_PRODUCT_PACKAGE_QUANTITY,
  ASSIGN_PRODUCT_PACKAGE_SKU,
  CREATE_PRODUCT_PACKAGE_SUCCESS,
  DELETE_PRODUCT_PACKAGE_SUCCESS,
  NEW_PRODUCT_PACKAGE,
  PULL_PRODUCT_PACKAGE,
  UPDATE_PRODUCT_PACKAGE_SUCCESS
} from "redux/model/productPackages/productPackage/constants";
import {
  INIT_PRODUCT_PACKAGES,
  LIST_PRODUCT_PACKAGES,
  LIST_PRODUCT_PACKAGES_FAILED,
  LIST_PRODUCT_PACKAGES_SUCCESS
} from "redux/model/productPackages/productPackages/constants";
import { extractIdFrom, isNew } from "@tmatt-tech/react_common";
import { NEW_PRODUCT_PACKAGE_MAPPER } from "redux/model/productPackageMappers/productPackageMapper/constants";

const INIT_STATE = {
  productPackages: {},
  loading: true,
  error: null,
};

const productPackagesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_PACKAGES:
      return INIT_STATE;

    case LIST_PRODUCT_PACKAGES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PRODUCT_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productPackages: _.mapKeys(action.payload.productPackages, 'id'),
      };

    case LIST_PRODUCT_PACKAGES_FAILED:
      return {
        ...state,
        productPackages: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        productPackages: {
          [action.payload.productPackage.id]: action.payload.productPackage,
          ...state.productPackages,
        },
      };

    case UPDATE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: action.payload.productPackage,
        },
      };

    case DELETE_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        productPackages: _.omit(state.productPackages, action.payload.productPackage.id)
      };

    case NEW_PRODUCT_PACKAGE:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.dummyId]: {
            id: action.payload.dummyId,
            destroy: false,
          },
        },
      };

    case PULL_PRODUCT_PACKAGE:
      if (isNew(action.payload.productPackage)) {
        return {
          ...state,
          productPackages: _.omit(state.productPackages, action.payload.productPackage.id),
        };
      }

      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: {
            ...state.productPackages[action.payload.productPackage.id],
            destroy: true,
          }
        }
      };

    case NEW_PRODUCT_PACKAGE_MAPPER:
      const newProductPackageDummyId = _.replace(action.payload.dummyId, 'productPackageMapper', 'productPackage');

      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [newProductPackageDummyId]: {
            id: newProductPackageDummyId,
            optionItemIds: _.map(action.payload.optionItems, 'id'),
            optionItemTitles: _.map(action.payload.optionItems, 'title'),
            destroy: false,
          },
        },
      };

    case ASSIGN_PRODUCT_PACKAGE_OPTION_ITEM_TITLES:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: {
            ...state.productPackages[action.payload.productPackage.id],
            optionItemTitles: action.payload.optionItemTitles,
          },
        },
      };

    case ASSIGN_PRODUCT_PACKAGE_PRICE:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: {
            ...state.productPackages[action.payload.productPackage.id],
            price: action.payload.price,
          },
        },
      };

    case ASSIGN_PRODUCT_PACKAGE_QUANTITY:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: {
            ...state.productPackages[action.payload.productPackage.id],
            quantity: action.payload.quantity,
          },
        },
      };

    case ASSIGN_PRODUCT_PACKAGE_SKU:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: {
            ...state.productPackages[action.payload.productPackage.id],
            sku: action.payload.sku,
          },
        },
      };

    case ASSIGN_PRODUCT_PACKAGE_DESTROY:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          [action.payload.productPackage.id]: {
            ...state.productPackages[action.payload.productPackage.id],
            destroy: action.payload.destroy,
          },
        },
      };

    default:
      return { ...state };
  }
};

export default productPackagesReducers;
