import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_POINT_EXCHANGE,
  DELETE_POINT_EXCHANGE,
  FETCH_POINT_EXCHANGE,
  UPDATE_POINT_EXCHANGE
} from "redux/model/pointExchanges/pointExchange/constants";
import {
  apiCreatePointExchange,
  apiDeletePointExchange,
  apiFetchPointExchange,
  apiUpdatePointExchange
} from "api/pointExchange/apiPointExchange";
import {
  createPointExchangeFailed,
  createPointExchangeSuccess,
  deletePointExchangeFailed,
  deletePointExchangeSuccess,
  fetchPointExchangeFailed,
  fetchPointExchangeSuccess,
  initPointExchange,
  updatePointExchangeFailed,
  updatePointExchangeSuccess
} from "redux/model/pointExchanges/pointExchange/pointExchangeActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_POINT_EXCHANGES_PATH } from "routes/pointExchange/constants";


function* pointExchangeSaga() {
  yield all([
    fork(watchFetchPointExchange),
    fork(watchUpdatePointExchange),
    fork(watchCreatePointExchange),
    fork(watchDeletePointExchange),
  ]);
}

// Fetch PointExchange
export function* watchFetchPointExchange() {
  yield takeEvery(FETCH_POINT_EXCHANGE, sagaFetchPointExchange);
}

export function* sagaFetchPointExchange({ payload }) {
  try {
    const response = yield call(apiFetchPointExchange, payload);

    const { pointExchange } = response.data;
    yield put(fetchPointExchangeSuccess({ pointExchange }));

  } catch (e) {
    yield put(fetchPointExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create PointExchange
export function* watchCreatePointExchange() {
  yield takeLeading(CREATE_POINT_EXCHANGE, sagaCreatePointExchange);
}

export function* sagaCreatePointExchange({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePointExchange, payload);

    const { pointExchange } = response.data;
    yield put(createPointExchangeSuccess({ pointExchange }));
    yield put(toastSaved({ name: `PointExchange` }));


    history.push(generateRoute(INDEX_POINT_EXCHANGES_PATH));
    yield put(initPointExchange());


  } catch (e) {
    yield put(createPointExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update PointExchange
export function* watchUpdatePointExchange() {
  yield takeLeading(UPDATE_POINT_EXCHANGE, sagaUpdatePointExchange);
}

export function* sagaUpdatePointExchange({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePointExchange, payload);

    const { pointExchange } = response.data;
    yield put(updatePointExchangeSuccess({ pointExchange }));
    yield put(toastSaved({ name: 'PointExchange' }));
    history.push(generateRoute(INDEX_POINT_EXCHANGES_PATH));
    yield put(initPointExchange());

  } catch (e) {
    yield put(updatePointExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete PointExchange
export function* watchDeletePointExchange() {
  yield takeLeading(DELETE_POINT_EXCHANGE, sagaDeletePointExchange);
}

export function* sagaDeletePointExchange({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePointExchange, payload);

    const { pointExchange } = response.data;
    yield put(deletePointExchangeSuccess({ pointExchange }));
    yield put(toastDeleted({ name: 'PointExchange' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePointExchangeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default pointExchangeSaga;
