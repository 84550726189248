import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  DOWNLOAD_DISPATCHING_ORDERS_CSV,
  DOWNLOAD_PROCESSING_ORDERS_CSV,
  LIST_ORDER_PACKS, LIST_ORDER_PACKS_CAN_BE_MERGED
} from "redux/model/orderPacks/orderPacks/constants";
import {
  apiDownloadDispatchingOrdersCsv,
  apiDownloadProcessingOrdersCsv,
  apiListOrderPacks, apiListOrderPacksCanBeMerged
} from "api/orderPack/apiOrderPack";
import { listOrderPacksFailed, listOrderPacksSuccess } from "redux/model/orderPacks/orderPacks/orderPacksActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";
import fileDownload from "js-file-download";


function* orderPacksSaga() {
  yield all([
    fork(watchListOrderPacks),
    fork(watchListOrderPacksCanBeMerged),
    fork(watchDownloadProcessingOrdersCsv),
    fork(watchDownloadDispatchingOrdersCsv),
  ]);
}

// List OrderPacks
export function* watchListOrderPacks() {
  yield takeEvery(LIST_ORDER_PACKS, sagaListOrderPacks);
}

export function* sagaListOrderPacks({ payload }) {
  try {
    const response = yield call(apiListOrderPacks, payload);

    const { orderPacks, totalSize } = response.data;

    yield put(listOrderPacksSuccess({ orderPacks, totalSize }));

  } catch (e) {
    yield put(listOrderPacksFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// List OrderPacks Can Be Merged
export function* watchListOrderPacksCanBeMerged() {
  yield takeEvery(LIST_ORDER_PACKS_CAN_BE_MERGED, sagaListOrderPacksCanBeMerged);
}

export function* sagaListOrderPacksCanBeMerged({ payload }) {
  try {
    const response = yield call(apiListOrderPacksCanBeMerged, payload);

    const { orderPacks } = response.data;

    yield put(listOrderPacksSuccess({ orderPacks }));

  } catch (e) {
    yield put(listOrderPacksFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Download Processing Orders Csv
export function* watchDownloadProcessingOrdersCsv() {
  yield takeEvery(DOWNLOAD_PROCESSING_ORDERS_CSV, sagaDownloadProcessingOrdersCsv);
}

export function* sagaDownloadProcessingOrdersCsv({ payload }) {
  try {
    const response = yield call(apiDownloadProcessingOrdersCsv, payload);
    yield call(
      fileDownload,
      response.data,
      `待处理订单.csv`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}

// Download Dispatching Orders Csv
export function* watchDownloadDispatchingOrdersCsv() {
  yield takeEvery(DOWNLOAD_DISPATCHING_ORDERS_CSV, sagaDownloadDispatchingOrdersCsv);
}

export function* sagaDownloadDispatchingOrdersCsv({ payload }) {
  try {
    const response = yield call(apiDownloadDispatchingOrdersCsv, payload);
    yield call(
      fileDownload,
      response.data,
      `待发货订单.csv`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}


export default orderPacksSaga;
