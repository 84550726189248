import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { extractClassNameFrom, generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_LINE_ATTACHMENT,
  CREATE_ORDER_ATTACHMENT,
  CREATE_PACK_ATTACHMENT,
  DELETE_LINE_ATTACHMENT,
  DOWNLOAD_LINE_ATTACHMENT,
  FETCH_LINE_ATTACHMENT,
  UPDATE_LINE_ATTACHMENT
} from "redux/model/lineAttachments/lineAttachment/constants";
import {
  apiCreateLineAttachment,
  apiCreateOrderAttachment,
  apiCreatePackAttachment,
  apiDeleteLineAttachment,
  apiDownloadLineAttachment,
  apiFetchLineAttachment,
  apiUpdateLineAttachment
} from "api/lineAttachment/apiLineAttachment";
import {
  createLineAttachmentFailed,
  createLineAttachmentSuccess,
  deleteLineAttachmentFailed,
  deleteLineAttachmentSuccess,
  fetchLineAttachmentFailed,
  fetchLineAttachmentSuccess,
  updateLineAttachmentFailed,
  updateLineAttachmentSuccess
} from "redux/model/lineAttachments/lineAttachment/lineAttachmentActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import _ from 'lodash';
import {
  createAttachmentListAttachment,
  createAttachmentListAttachmentSuccess
} from "redux/model/attachmentLists/attachmentLists/attachmentListsActions";
import {
  createTransactionAttachment,
  createTransactionAttachmentSuccess
} from "redux/model/transactions/transaction/transactionActions";

const fileDownload = require('js-file-download');


function* lineAttachmentSaga() {
  yield all([
    fork(watchFetchLineAttachment),
    fork(watchUpdateLineAttachment),
    fork(watchCreateLineAttachment),
    fork(watchCreatePackAttachment),
    fork(watchCreateOrderAttachment),
    fork(watchDeleteLineAttachment),
    fork(watchDownloadLineAttachment),
  ]);
}

// Fetch LineAttachment
export function* watchFetchLineAttachment() {
  yield takeEvery(FETCH_LINE_ATTACHMENT, sagaFetchLineAttachment);
}

export function* sagaFetchLineAttachment({ payload }) {
  try {
    const response = yield call(apiFetchLineAttachment, payload);

    const { lineAttachment } = response.data;
    yield put(fetchLineAttachmentSuccess({ lineAttachment }));

  } catch (e) {
    yield put(fetchLineAttachmentFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create LineAttachment
export function* watchCreateLineAttachment() {
  yield takeEvery(CREATE_LINE_ATTACHMENT, sagaCreateLineAttachment);
}

export function* sagaCreateLineAttachment({ payload }) {
  try {
    const { attachable, lineAttachment: dummyLineAttachment } = payload;

    if (attachable) {
      switch (extractClassNameFrom(attachable.id)) {
        case 'carousel':
          yield put(createAttachmentListAttachment({ attachmentList: attachable, lineAttachment: dummyLineAttachment }));
          break;

        case 'content':
          yield put(createAttachmentListAttachment({ attachmentList: attachable, lineAttachment: dummyLineAttachment }));
          break;

        case 'transaction':
          yield put(createTransactionAttachment({ lineAttachment: dummyLineAttachment }));
          break;

        default:
          // do nothing
      }
    }


    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateLineAttachment, payload);

    const { lineAttachment } = response.data;
    yield put(createLineAttachmentSuccess({ lineAttachment }));
    yield put(toastSaved({ name: `Attachment` }));

    if (attachable) {
      switch (extractClassNameFrom(attachable.id)) {
        case 'carousel':
          yield put(createAttachmentListAttachmentSuccess({ attachmentList: attachable, lineAttachment }));
          break;

        case 'content':
          yield put(createAttachmentListAttachmentSuccess({ attachmentList: attachable, lineAttachment }));
          break;

        case 'transaction':
          yield put(createTransactionAttachmentSuccess({ lineAttachment: lineAttachment }));
          break;

        default:
        // do nothing
      }
    }


  } catch (e) {
    yield put(createLineAttachmentFailed({
      ...payload,
      ...generateErrorMessage(e),
    }));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Create Pack Attachment
export function* watchCreatePackAttachment() {
  yield takeEvery(CREATE_PACK_ATTACHMENT, sagaCreatePackAttachment);
}

export function* sagaCreatePackAttachment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);

    const response = yield call(apiCreatePackAttachment, payload);

    const { lineAttachment } = response.data;
    yield put(createLineAttachmentSuccess({ lineAttachment }));

  } catch (e) {
    yield put(createLineAttachmentFailed({
      ...payload,
      ...generateErrorMessage(e),
    }));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Create Order Attachment
export function* watchCreateOrderAttachment() {
  yield takeEvery(CREATE_ORDER_ATTACHMENT, sagaCreateOrderAttachment);
}

export function* sagaCreateOrderAttachment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);

    const response = yield call(apiCreateOrderAttachment, payload);

    const { lineAttachment } = response.data;
    yield put(createLineAttachmentSuccess({ lineAttachment }));

  } catch (e) {
    yield put(createLineAttachmentFailed({
      ...payload,
      ...generateErrorMessage(e),
    }));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update LineAttachment
export function* watchUpdateLineAttachment() {
  yield takeLeading(UPDATE_LINE_ATTACHMENT, sagaUpdateLineAttachment);
}

export function* sagaUpdateLineAttachment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateLineAttachment, payload);

    const { lineAttachment } = response.data;
    yield put(updateLineAttachmentSuccess({ lineAttachment }));
    yield put(toastSaved({ name: 'Attachment' }));

  } catch (e) {
    yield put(updateLineAttachmentFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete LineAttachment
export function* watchDeleteLineAttachment() {
  yield takeLeading(DELETE_LINE_ATTACHMENT, sagaDeleteLineAttachment);
}

export function* sagaDeleteLineAttachment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteLineAttachment, payload);

    const { lineAttachment } = response.data;
    yield put(deleteLineAttachmentSuccess({ lineAttachment }));
    yield put(toastDeleted({ name: 'Attachment' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteLineAttachmentFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Download LineAttachment
export function* watchDownloadLineAttachment() {
  yield takeEvery(DOWNLOAD_LINE_ATTACHMENT, sagaDownloadLineAttachment);
}

export function* sagaDownloadLineAttachment({ payload }) {
  try {
    const response = yield call(apiDownloadLineAttachment, payload);
    yield call(fileDownload, response.data, payload.lineAttachment.title);

  } catch (e) {
    throwFrontError(e);
  }
}


export default lineAttachmentSaga;
