import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PRODUCT_PACKAGES } from "redux/model/productPackages/productPackages/constants";
import { apiListProductPackages } from "api/productPackage/apiProductPackage";
import { listProductPackagesFailed, listProductPackagesSuccess } from "redux/model/productPackages/productPackages/productPackagesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* productPackagesSaga() {
  yield all([
    fork(watchListProductPackages),
  ]);
}

// List ProductPackages
export function* watchListProductPackages() {
  yield takeEvery(LIST_PRODUCT_PACKAGES, sagaListProductPackages);
}

export function* sagaListProductPackages() {
  try {
    const response = yield call(apiListProductPackages);

    const { productPackages } = response.data;

    yield put(listProductPackagesSuccess({ productPackages }));

  } catch (e) {
    yield put(listProductPackagesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default productPackagesSaga;
