
export const CREATE_PRODUCT_PACKAGE_API = {
  url: `/productPackages`,
  method: 'post'
};

export const INDEX_PRODUCT_PACKAGES_API = {
  url: `/productPackages`,
  method: 'get',
};

export const FETCH_PRODUCT_PACKAGE_API = {
  url: `/productPackages/:id`,
  method: 'get',
};

export const UPDATE_PRODUCT_PACKAGE_API = {
  url: `/productPackages/:id`,
  method: 'put'
};

export const DELETE_PRODUCT_PACKAGE_API = {
  url: `/productPackages/:id`,
  method: 'delete'
};
