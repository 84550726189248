import {
  FETCH_PRODUCT_PACKAGE_MAPPER,
  FETCH_PRODUCT_PACKAGE_MAPPER_FAILED,
  FETCH_PRODUCT_PACKAGE_MAPPER_SUCCESS,
  INIT_PRODUCT_PACKAGE_MAPPER
} from "redux/model/productPackageMappers/productPackageMapper/constants";

const INIT_STATE = {
  productPackageMapper: {},
  loading: true,
  error: null,
};

const productPackageMapperReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_PACKAGE_MAPPER:
      return INIT_STATE;

    case FETCH_PRODUCT_PACKAGE_MAPPER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRODUCT_PACKAGE_MAPPER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productPackageMapper: action.payload.productPackageMapper,
      };

    case FETCH_PRODUCT_PACKAGE_MAPPER_FAILED:
      return {
        ...state,
        productPackageMapper: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default productPackageMapperReducers;
