import {
  INIT_PRODUCT_PACKAGES,
  LIST_PRODUCT_PACKAGES,
  LIST_PRODUCT_PACKAGES_FAILED,
  LIST_PRODUCT_PACKAGES_SUCCESS
} from "redux/model/productPackages/productPackages/constants";

export const initProductPackages = () => ({
  type: INIT_PRODUCT_PACKAGES,
  payload: {}
});

export const listProductPackages = () => ({
  type: LIST_PRODUCT_PACKAGES,
  payload: {}
});

export const listProductPackagesSuccess = ({ productPackages }) => ({
  type: LIST_PRODUCT_PACKAGES_SUCCESS,
  payload: { productPackages }
});

export const listProductPackagesFailed = ({ error }) => ({
  type: LIST_PRODUCT_PACKAGES_FAILED,
  payload: { error }
});
