import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_POINT_EXCHANGE_API,
  INDEX_POINT_EXCHANGES_API,
  FETCH_POINT_EXCHANGE_API,
  DELETE_POINT_EXCHANGE_API,
  UPDATE_POINT_EXCHANGE_API
} from "api/pointExchange/constants";

export const apiListPointExchanges = () => {
  const { url, method } = INDEX_POINT_EXCHANGES_API;

  return api({
    method,
    url,
  });
};

export const apiCreatePointExchange = ({ pointExchange }) => {
  const { url, method } = CREATE_POINT_EXCHANGE_API;

  return api({
    method,
    url,
    data: pointExchangeParams({ pointExchange }),
  });
};

export const apiFetchPointExchange = ({ pointExchangeId }) => {
  const { url, method } = FETCH_POINT_EXCHANGE_API;

  return api({
    method,
    url: generateRoute(url, pointExchangeId),
  });
};

export const apiUpdatePointExchange = ({ pointExchange }) => {
  const { url, method } = UPDATE_POINT_EXCHANGE_API;

  return api({
    method,
    url: generateRoute(url, pointExchange.id),
    data: pointExchangeParams({ pointExchange }),
  });
};

export const apiDeletePointExchange = ({ pointExchange }) => {
  const { url, method } = DELETE_POINT_EXCHANGE_API;

  return api({
    method,
    url: generateRoute(url, pointExchange.id),
  });
};

const pointExchangeParams = ({ pointExchange }) => {
  const {
    rateOut,
    rateIn,
  } = pointExchange;

  return {
    rateOut,
    rateIn,
  }
};
