import {
  FETCH_PRODUCT_PACKAGE,
  FETCH_PRODUCT_PACKAGE_FAILED,
  FETCH_PRODUCT_PACKAGE_SUCCESS,
  INIT_PRODUCT_PACKAGE
} from "redux/model/productPackages/productPackage/constants";

const INIT_STATE = {
  productPackage: {},
  loading: true,
  error: null,
};

const productPackageReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRODUCT_PACKAGE:
      return INIT_STATE;

    case FETCH_PRODUCT_PACKAGE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRODUCT_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productPackage: action.payload.productPackage,
      };

    case FETCH_PRODUCT_PACKAGE_FAILED:
      return {
        ...state,
        productPackage: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default productPackageReducers;
