export const INIT_OPTIONS = 'INIT_OPTIONS';

export const LIST_OPTIONS = 'LIST_OPTIONS';
export const LIST_OPTIONS_SUCCESS = 'LIST_OPTIONS_SUCCESS';
export const LIST_OPTIONS_FAILED = 'LIST_OPTIONS_FAILED';

export const NEW_OPTION = 'NEW_OPTION';
export const PULL_OPTION_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS = 'PULL_OPTION_SETTLE_DOWN_PRODUCT_PACKAGE_MAPPERS';
export const PULL_OPTION = 'PULL_OPTION';
export const ASSIGN_OPTION_TITLE = 'ASSIGN_OPTION_TITLE';

