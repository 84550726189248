import _ from 'lodash';
import {
  CREATE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_SUCCESS
} from "redux/model/collections/collection/constants";
import {
  INIT_COLLECTIONS,
  LIST_COLLECTIONS,
  LIST_COLLECTIONS_FAILED,
  LIST_COLLECTIONS_SUCCESS
} from "redux/model/collections/collections/constants";

const INIT_STATE = {
  collections: {},
  loading: true,
  error: null,
};

const collectionsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_COLLECTIONS:
      return INIT_STATE;

    case LIST_COLLECTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: _.mapKeys(action.payload.collections, 'id'),
      };

    case LIST_COLLECTIONS_FAILED:
      return {
        ...state,
        collections: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          [action.payload.collection.id]: action.payload.collection,
          ...state.collections,
        },
      };

    case UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.payload.collection.id]: action.payload.collection,
        },
      };

    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: _.omit(state.collections, action.payload.collection.id)
      };

    default:
      return { ...state };
  }
};

export default collectionsReducers;
